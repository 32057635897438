#AddImage{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;

    .adContent{
        overflow: hidden;
        position: relative;
        width: 400px;
        min-height: 420px;
        background-color: lightblue; 
        z-index: 110;
        border-radius: 1vmax;   
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .adContent h1{
        margin-top: 30px;
        text-align: center;
    }
    .close-tab{
        position: absolute;
        top: 0px;
        right: 10px;
        margin: 5px;
        font-size: 20px;
        cursor: pointer;
    }
    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 330px;
    }
    .form p{
        position: relative;  
        margin: 10px;    
        width: 100%;  
    }
    .form input{
        width: 100%;
    }
    .form textarea{
        position: relative;
        width: 100%;
        height: 130px;
    }
    .form button{
        height: 30px;
        width: 100px;
        border-radius: 50vmax;
        margin-top: 5%;
    }
    @media (max-width:450px){
        .adContent{
            width: 100vw;
            min-height: 100vh;
            border-radius: 0vmax;   
        }
        .form {
            width: 90vw;
        }
        .adContent h1{
            font-size: 20px
        }
        .form button{
            width: 80px;
            margin-top: 10%;
        }
    }
}
#AddImage::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255,255,255, 0.3);
}
#AddImage::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255,255,255, 0.7);
    border-radius: 4vmax;
}
@media (max-width:450px){
    #AddImage::-webkit-scrollbar{
        width: 0px;
    }
    #AddImage::-webkit-scrollbar-thumb{
        width: 0px;
    } 
}