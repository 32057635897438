#BookingsList{
    background-color: rgba(0,0,0,0.4);
    height: 100vh;
    position: fixed;
    top: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 100;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    
    .Bookings-list{
        width: 400px;
        height: 80vh;
        margin-top: 10vh;
        background-color: lightblue;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 0.5vmax;
        display: grid;
        grid-template-rows: 90px 45px 1fr;
        position: relative;
        justify-items: center;
    }

    .search-tab{
        width: 95%;
        margin-top: 40px;
        height: 40px;
        outline: none;
        border: 0px;
        border-radius: 0.5vmax;
    }

    .booking-tabs{
        width: 90%;
        height: 90%;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 0.5vmax;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 5px;
    }

    .booking-tabs p{
        text-align: center;
        width: 100%;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
    }

    .tab1{
        box-shadow: 0px 0px 1px 0px;
        border-top-left-radius: 0.5vmax;
        border-bottom-left-radius: 0.5vmax;
        height: 100%;
        margin: 0px;
    }
    .tab2{
        box-shadow: 0px 0px 1px 0px;
        border-top-right-radius: 0.5vmax;
        border-bottom-right-radius: 0.5vmax;
        height: 100%;
        margin: 0px;
    }

    .selected{
        box-shadow: 0px 0px 4px 0px;
    }

    .close-bookings{
        position: absolute;
        top: -15px;
        right: 20px;
        font-size: 20px;
        font-weight: bold;
        color: black;
        cursor: pointer;
    }

    .list{
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100%;
        width: 100%;
    }
    .list::-webkit-scrollbar{
        width: 6px;
        background-color: rgba(0,0,0,0.4);
    }
    .list::-webkit-scrollbar-thumb{
        border-radius: 4vmax;
        background-color: rgba(0,0,0,0.8);
    }

    .bookingCard{
        width: 95%;
        height: 70px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        margin-left: 2.5%;
        margin-top: 10px;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 70px 1fr;
        cursor: pointer;
    }
    .profile-frame{
        height: 50px;
        width: 50px;
        border-radius: 100%;
        border: 3px solid lightblue;
        box-shadow: 0px 0px 2px 0px black;
        margin: 8px;
        background-color: rgb(53, 103, 119);
    }
    .profile-frame img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 100%;
    }
    .data{
        display: grid;
        grid-template-rows: 50% 50%;
    }
    .bookerData{
        display: grid;
        grid-template-columns: 1fr 60px;
    }
    .name{
        font-size: 15px;
        font-weight: bold;
        width: fit-content;
        margin-left: 15px;
    }
    .time{
        font-size: 12px;
        font-weight: bold;
        width: 100%;
        margin-right: 5px;
    }

    @media(max-width: 600px){

        .Bookings-list{
            width: 100vw;
            height: 100vh;
            margin-top: 0vh;
            border-radius: 0vmax;
            justify-items: center;
        }
        time{
            font-size: 10px;
        }
        .name{
            font-size: 12px;
        }
    }
}

#BookingsList::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255, 255, 255, 0.3);
}

#BookingsList::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 4vmax;
}

@media (max-width: 600px){
    #BookingsList::-webkit-scrollbar{
        width: 0px;
    }
    
    #BookingsList::-webkit-scrollbar-thumb{
        width: 0px;
    }
}