#Services{
    margin-top: 130px;
    width: 100dvw;
    min-height: 533.33px;
    position: relative;
    background-color: rgba(173,216,230,0.2);

    .thumbnail-block{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100dvw;
    }
    
    .video-block{
        display: grid;
        flex-direction: row;
        overflow-x: scroll;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        width: 100dvw;
        min-height: 531px;
    }
    
    .filter-block{
        height: 50px;
        width: 100dvw;
        box-shadow: 0px 0px 2px 0px black;
        position: fixed;
        top: 78px;
        background-color: white;
        z-index: 5;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .video-block::-webkit-scrollbar,
    .filter-block::-webkit-scrollbar{
        height: 1px;
        background-color: rgba(0,0,0,0.3);
    }
    .video-block::-webkit-scrollbar-thumb,
    .filter-block::-webkit-scrollbar-thumb{
        height: 1px;
        background-color: rgba(0,0,0,0.7);
        border-radius: 4vmax;
        cursor: pointer;
    }
    
    .filter-block p{
        margin-top: 10px;
        margin-bottom: 4px;
        margin-left: 3px;
        margin-right: 3px;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 3px;
        color: white;
        background-color: rgba(173,216,230,0.7);
        opacity: 1;
        border-radius: 0.5vmax;
        height: 30px;
        width:fit-content;
        font-size: 17px;
        white-space: nowrap;
        cursor: pointer;
        position: relative;
    }
    
    .selected{
        box-shadow: 0px 0px 2px 0px black;
        background-color: rgb(60, 136, 161) !important;
    }

    .previous-filter{
        height: 38px;
        width: 38px;
        border-radius: 100vmax;
        background-color: rgba(0,0,0,0.5);
        position: relative;
        top: 7px;
        left: 25px;
    }
    
    .previous-filter img{
        height: 30px;
        width: 30px;
        position: relative;
        top: 7%;
        left: 5%;
        cursor: pointer;
    }
    
    .next-filter{
        height: 38px;
        width: 38px;
        border-radius: 100vmax;
        background-color: rgba(0,0,0,0.5);
        position: relative;
        top: 7px;
        left: 30px;
        cursor: pointer;
    }
    
    .next-filter img{
        height: 30px;
        width: 30px;
        position: relative;
        top: 8%;
        left: 12%;
    }
    @media (max-width: 1250px){
        .video-block{
            display: flex;
            grid-template-columns: none;
        }
    }
    @media (max-width: 1200px){
        .thumbnail-block{
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100dvw;
        }

        .video-block{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100dvw;
        }
    }

    @media (max-width: 800px){
        .thumbnail-block{
            display: grid;
            grid-template-columns: 1fr;
            width: 100dvw;
        }

        /* .video-block{
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100dvw;
        } */
    }
}
