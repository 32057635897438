#ServicerEditor{
    .fainted{
        height: 100vh;
        width: 100dvw;
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .fainted::-webkit-scrollbar{
        background-color: rgba(255,255,255,0.3);
        width: 10px;
    }
    .fainted::-webkit-scrollbar-thumb{
        background-color: rgba(255,255,255,0.7);
        width: 10px;
        border-radius: 4vmax;
    }
    .form-block{
        width: 800px;
        height: fit-content;
        background-color: lightblue;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 0.5vmax;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .closer{
        font-size: 20px;
        font-weight: bold;
        color: black;
        position: absolute;
        right: 15px;
        top: 2px;
        cursor: pointer;
    }
    .main-header{
        text-align: center;
        font-size: 40px;
        color: black;
    }
    form{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .input-block{
        height: fit-content;
    }
    .input-block p{
        position: relative;
        left:18%;
    }
    .input-block input{
        position: relative;
        left:18%;
        height: 30px;
        width: 62%;
        border-radius: 0.2vmax;
    }
    .input-block textarea{
        width: 62%;
    }
    .description{
        position: relative;
        left:18%;
        height: 100px;
        width: 250px;
        border-radius: 0.2vmax;
    }
    .profileInput{
        border-radius: 100%;
        border: 4px solid lightblue;
        height: 120px;
        width: 120px;
        position: relative;
        left: 18%;
        box-shadow: 0px 0px 2px 0px black;
        background-color: rgb(53, 103, 119);
    }
    .profileInput img{
        object-fit: contain;
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
    .closeImg{
        font-size: 20px;
        margin: 0px;
        color: red;
        position: absolute !important;
        left: 130px !important;
        top: 0px !important;
        cursor: pointer;
        width: fit-content;
    }
    .submit-button{
        height: 40px;
        width: 150px;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 100vmax;
        margin-top: 20px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    @media (max-width: 850px){
        .form-block{
            width: 600px;
        }
    }
    @media (max-width: 700px){
        .form-block{
            width: 95%;
        }
        .closer{
            right: 10px;
            top: -17px;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-template-columns: none;
        }
        .input-block{
            width: 80%;
        }
        .input-block p{
            left:0px;
            width: 100%;
        }
        .input-block input{
            left:0px;
            width: 100%;
        }
        .input-block textarea{
            left:0px;
            width: 100%;
        }
    }
    @media (max-width: 450px){
        .fainted::-webkit-scrollbar{
            width: 0px;
        }
        .fainted::-webkit-scrollbar-thumb{
            width: 0px;
        }
        .form-block{
            width: 100%;
            height: fit-content;
            border-radius: 0vmax;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}