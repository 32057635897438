#ErrorPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
        font-size: 150px;
        margin-bottom: 20px;
        background: linear-gradient(to right, #000428, #004e92, #00c6ff);
        -webkit-background-clip: text;
        background-clip: text; 
        -webkit-text-fill-color: transparent; 
    }
    h3{
        font-size: 20px;
    }

    @media (max-width:450px){
        h1{
            font-size: 90px; 
        }
        h3{
            font-size: 15px;
        }
    }

    @media (max-width:280px){
        h1{
            font-size: 70px; 
            margin-top: 70px;
        }
        h3{
            font-size: 12px;
        }
    }
}