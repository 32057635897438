#DeleteVideo{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;

    .delete-block{
        width: 400px;
        min-height: 200px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        position: relative;
        background-color: rgba(71, 149, 175, 0.9);
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .delete-block h2{
        text-align: center;
        margin-top: 30px;
    }
    .delete-block button{
        height: 35px;
        width: 120px;
        border-radius: 0.5vmax;
        position: absolute;
        bottom: 20px;
        cursor: pointer;
    }
    .delete-button{
        left: 60px;
    }
    .cancel-button{
        right: 60px;
    }

    @media (max-width:450px){
        .delete-block{
            width: 100vw;
            min-height: 100vh;
        }
        .delete-block button{
            height: 35px;
            width: 80px;
            border-radius: 0.5vmax;
            position: absolute;
            bottom: 20px;
            cursor: pointer;
        }
        .delete-button{
            left: 20%;
        }
        .cancel-button{
            right: 20%;
        }
    }

    @media (max-width:300px){
        .delete-button{
            left: 10%;
        }
        .cancel-button{
            right: 10%;
        }
    }
}
#DeleteVideo::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255,255,255,0.3);
}
#DeleteVideo::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255,255,255,0.7);
}

@media (max-width:450px){
    #DeleteVideo::-webkit-scrollbar{
        width: 0px;
    }
    #DeleteVideo::-webkit-scrollbar-thumb{
        width: 0px;
    }
}