a,
button{
    user-select: none;
    outline: none;
}

#Layout{ 
    body{
        margin: 0px;
    }

    a{
        text-decoration: none;
    }

    .Header{
        height: 80px;
        width: 100dvw;
        background-color: lightblue;
        display: grid;
        grid-template-columns: 150px 120px 120px 120px 1fr 120px 120px 120px 120px;
        position: fixed;
        top: 0px;
        z-index: 14;
        box-shadow: 0px 0px 1px 0px black;
    }

    .Footer{
        width: 100dvw;
        display: grid;
        grid-template-rows:300px 80px 320px;
        border-top: solid 0.1px gray;
    }

    .logo{
        font-family: 'Great Vibes', cursive;
        color: white;
        margin-left: 5px;
        font-size: 31px;
    }
    .logo-large{
        font-family: 'Great Vibes', cursive;
        color: white;
        font-size: 170px;
    }

    .left-links{
        display: grid;
        grid-template-columns: 50px 1fr;
    }

    .left-links img{
        height: 25px;
        width: 25px;
        position: relative;
        left: 22px;
        top: 26px;
    }

    .left-links p{
        width: fit-content;
        margin-top: 30px;
        margin-bottom: 0px;
        color: white;
    }

    .right-links p:hover,
    .left-links p:hover{
        color: darkblue;
    }
    .selected{
        color: darkblue;
    }

    .logo img{
        height: 60px;
        width: 150px;
        position: relative;
        left: 240px;
        top: 10px;
    }
    .logo2 img{
        height: 60px;
        width: 150px;
        position: relative;
        top: 10px;
    }

    .right-links p{
        margin-top: 30px;
        margin-bottom: 0px;
        text-align: center;
        color: white;
    }

    .signup-link button{
        position: relative;
        top: 20px;
        left: 20px;
        height: 35px;
        width: 80px;
        border-radius: 2vmax;
        color: white;
        background-color: black;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: none;
    }
    .menu{
        position: absolute;
        right: 40px;
        top: 20px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: none;
    }
    .menu-tab{
        height: 100vh;
        overflow-y: scroll;
        background-color: lightblue;
        box-shadow: 0px 0px 2px 0px black;
        position: fixed;
        right: 0px;
        top: 0px;    
        z-index: 100;
        transition: 2s ease-in-out;
        display: none;
        grid-template-rows: 80px 1fr;
    }
    .menu-tab::-webkit-scrollbar{
        width: 10px;
        background-color: rgba(0,0,0,0.3);
    }
    .menu-tab::-webkit-scrollbar-thumb{
        width: 10px;
        background-color: rgba(0,0,0,0.7);
        border-radius: 4vmax;
    }
    .signupBlock{
        background-color: black;
        display: grid;
        grid-template-columns: 30.349dvw 1fr ;
    }

    .s_text{
        color:  white;
        margin: 0dvw;
        font-size: 1.517dvw;
        position: relative;
        top: 35%;
        left: 5%; 
    }

    .searchBar{
        width: 37.936dvw;
        height: 3.794dvw;
        position: relative;
        top: 20%;
        font-size: 1.138dvw;
    }

    .signupButton{
        position: relative;
        left: 10%;
        top: 21%;
        height: 4.552dvw;
        width: 11.381dvw;
        cursor: pointer;
        font-size: 1.138dvw;
    }

    .linksBlock{
        background-color: lightblue;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100dvw;
    }

    .connectBlock{
        display: flex;
        flex-direction: column;
    }

    .socials{
        height: 35px;
        width: 35px;
        border-radius: 50%;
        margin-left: 70px;
        margin-bottom: 20px;
    }
    .socials img{
        height: 35px;
        width: 35px;
        border-radius: 50%;
        cursor: pointer;
    }

    .textLink{
        position: relative;
        left: 45px;
        color: black;
        font-size: 17px;
        text-decoration: none;
    }

    .categories{
        width: 800px;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .heading1,
    .heading2,
    .heading3{
        font-size: 23px;
        margin-left: 50px;
        margin-top: 30px;
    }

    .termsBlock{
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
    }

    .termsBlock p{
        color: white;
        width: 100%;
        text-align: center;
        margin: 0px;
        margin-top: 30px;
    }

    .terms-links{
        width: 400px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .posterBlock{
        background-color: lightblue;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .posterBlock img{
        height: 300px;
        width: 600px;
    }
    @media (max-width:1050px){
        .logo{
            font-family: 'Great Vibes', cursive;
            color: white;
            margin-left: 5px;
            font-size: 31px;
        }
        .logo-large{
            font-family: 'Great Vibes', cursive;
            color: white;
            font-size: 120px;
        }
        .right-links{
            display: none;
        }
        .left-links{
            display: none;
        }
        .Header a{
            display: none;
        }
        .signup-link{
            display: none;
        }
        .menu{
            display: block;
        }
        .menu-tab{
            display: grid;
        }
        .close-menu{
            font-size: 20px;
            font-weight: bold;
            position: absolute;
            top: 10px;
            right: 20px;
            cursor: pointer;
        }
        .bottom-block{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .menu-link{
            display: grid;
            grid-template-columns: 50px 1fr;
            align-items: center;
            height: 50px;
        }
    
        .menu-link img{
            height: 25px;
            width: 25px;
            position: relative;
            left: 22px;
        }
    
        .menu-link p{
            width: fit-content;
            margin: 0px;
            margin-left: 10px;
            color: white;
        }
        .menu-link p:hover{
            color: darkblue;
        }
        a{
            text-decoration: none;
        }
    }
    @media (max-width:900px){
        .socials{
            height: 35px;
            width: 35px;
            border-radius: 50%;
            margin-left: 40px;
            margin-bottom: 20px;
        }
        .socials img{
            height: 35px;
            width: 35px;
            border-radius: 50%;
        }
    
        .textLink{
            position: relative;
            left: 30px;
            color: black;
            font-size: 17px;
            text-decoration: none;
        }
       
        .heading1,
        .heading2,
        .heading3{
            font-size: 23px;
            margin-left: 50px;
            margin-top: 30px;
        }
        .socials{
            height: 35px;
            width: 35px;
            border-radius: 50%;
            margin-left: 70px;
            margin-bottom: 20px;
        }
        .socials img{
            height: 35px;
            width: 35px;
            border-radius: 50%;
        }
    
        .textLink{
            position: relative;
            left: 45px;
            color: black;
            font-size: 17px;
            text-decoration: none;
        }
        .posterBlock img{
            height: 250px;
            width: 500px;
        }
    }
    @media (max-width:800px){
        .logo{
            font-family: 'Great Vibes', cursive;
            color: white;
            margin-left: 5px;
            font-size: 31px;
        }
        .logo-large{
            font-family: 'Great Vibes', cursive;
            color: white;
            font-size: 100px;
        }
        .categories{
            width: 600px;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .heading1,
        .heading2,
        .heading3{
            font-size: 18px;
            margin-left: 30px;
            margin-top: 30px;
        }
        .socials{
            height: 35px;
            width: 35px;
            border-radius: 50%;
            margin-left: 40px;
            margin-bottom: 20px;
        }
        .socials img{
            height: 35px;
            width: 35px;
            border-radius: 50%;
        }
    
        .textLink{
            position: relative;
            left: 30px;
            color: black;
            font-size: 17px;
            /* font-weight: bold; */
            text-decoration: none;
        }
        .posterBlock img{
            height: 200px;
            width: 400px;
        }
        .Footer{
            width: 100dvw;
            display: grid;
            grid-template-rows:300px 80px 240px;
        }
    }
    @media (max-width:600px){
        .logo{
            font-family: 'Great Vibes', cursive;
            color: white;
            margin-left: 5px;
            font-size: 31px;
        }
        .logo-large{
            font-family: 'Great Vibes', cursive;
            color: white;
            font-size: 80px;
        }
        .categories{
            width: 500px;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .heading1,
        .heading2,
        .heading3{
            font-size: 15px;
            font-weight: bold;
            margin-left: 20px;
            margin-top: 30px;
        }
        .socials{
            height: 25px;
            width: 25px;
            border-radius: 50%;
            margin-left: 30px;
            margin-bottom: 20px;
        }
        .socials img{
            height: 25px;
            width: 25px;
            border-radius: 50%;
        }
    
        .textLink{
            position: relative;
            left: 22px;
            color: black;
            font-size: 14px;
            /* font-weight: bold; */
            text-decoration: none;
        }
        .Footer{
            width: 100dvw;
            display: grid;
            grid-template-rows:250px 80px 230px;
        }
    }
    @media (max-width:500px){
        .logo{
            font-family: 'Great Vibes', cursive;
            color: white;
            margin-left: 5px;
            font-size: 31px;
        }
        .logo-large{
            font-family: 'Great Vibes', cursive;
            color: white;
            font-size: 50px;
        }
        .categories{
            width: 95%;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .heading1,
        .heading2,
        .heading3{
            font-size: 15px;
            width: 100%;
            text-align: center;
            margin-left: 0px;
        }
        .socials{
            height: 25px;
            width: 25px;
            border-radius: 50%;
            margin-left: 0px;
            margin-bottom: 20px;
        }
        .socials img{
            height: 25px;
            width: 25px;
            border-radius: 50%;
        }
    
        .textLink{
            left: 0px;
            font-size: 12px;
            width: 100%;
            text-align: center;
        }
        .posterBlock img{
            height: 120px;
            width: 260px;
        }
        .Footer{
            width: 100dvw;
            display: grid;
            grid-template-rows:260px 80px 200px;
        }
        .terms-links{
            width: 90%;
            font-size: 12px;
            font-weight: bold;
        }
        .connectBlock{
            align-items: center;
        }
    }
    @media (max-width: 300px){
        .posterBlock img{
            height: 100px;
            width: 220px;
        }
        .logo{
            font-family: 'Great Vibes', cursive;
            color: white;
            margin-left: 5px;
            font-size: 25px;
            margin-top: 25px;
        }
        .logo-large{
            font-family: 'Great Vibes', cursive;
            color: white;
            font-size: 40px;
        }
    }
}