#AuthStatus{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    img{
        width: 100vw;
        height: 100vh;
        position:fixed;
        top: 0px;
        left:0px;
    }
    h1{
        width: 100vw;
        text-align: center;
        z-index: 10;
        font-size: 40px;
    }
    button{
        border-radius: 4vmax;
        background-color: black;
        color: white;
        width: 150px;
        height: 35px;
        z-index: 10;
    }

    @media(max-width:800px){
        h1{
            width: 100vw;
            text-align: center;
            z-index: 10;
            font-size: 30px;
        }
    }
    @media(max-width:600px){
        h1{
            width: 100vw;
            text-align: center;
            z-index: 10;
            font-size: 25px;
        }
    }
    @media(max-width:500px){
        h1{
            width: 100vw;
            text-align: center;
            z-index: 10;
            font-size: 20px;
        }
    }
    @media(max-width:400px){
        h1{
            width: 100vw;
            text-align: center;
            z-index: 10;
            font-size: 20px;
        }
    }
    @media(max-width:300px){
        h1{
            width: 100vw;
            text-align: center;
            z-index: 10;
            font-size: 15px;
        }
    }
}