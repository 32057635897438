#LocationSelector{
    .LocationButton{
        position: fixed;
        bottom: 20px;
        left: 7%;
        width: 120px;
        height: 40px;
        border-radius: 4vmax;
        box-shadow: 0px 0px 7px 0px darkblue;
        display: grid;
        grid-template-columns: 40px 1fr;
        background-color: rgba(173, 216, 230, 0.9);
        cursor: pointer;
        z-index: 13;
        align-items: center;
    }
    .pin{
        height: 20px;
        width: 20px;
        margin-left: 10px;
    }
    .city{
        width: 100%;
        /* text-align: center; */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
        font-weight: bold;
    }
    .background{
        width: 100dvw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(0,0,0,0.7);
        z-index: 110;
        overflow: hidden;
    }
    .location-block{
        width: 700px;
        min-height: 450px;
        margin-top: 70px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: lightblue;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .search-location{
        width: 500px;
        height: 40px;
        border-radius: 4vmax;
        border: solid gray 2px;
        margin-top: 30px;
        outline: none;
    }

    .map{
        width: 220px;
        height: 220px;
        background-color: gray;
        position: absolute;
        bottom: 100px;
        left: 70px;
        box-shadow: 0px 0px 2px 0px black;
    }
    .results{
        width: 300px;
        height: 265px;
        position: absolute;
        bottom: 90px;
        right: 40px;
        display: grid;
        grid-template-rows: 30px 1fr;
    }
    h2{
        margin: 0px;
        width: 95%;
        margin-left: 2.5%;
    }
    .list{
        height: 100%;
        overflow-y: scroll;
    }
    .list::-webkit-scrollbar{
        width: 8px;
        background-color: rgba(0,0,0,0.3);
    }
    .list::-webkit-scrollbar-thumb{
        background-color: rgba(0,0,0,0.7);
        border-radius: 4vmax;
        width: 8px;
    }
    .result-card{
        width: 95%;
        margin-left: 2.5%;
        margin-top: 5px;
        margin-bottom: 5px;
        height: 45px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
    }
    .result-card p{
        width: 95%;
        margin-left: 5%;
        font-size: 12px;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;     
        overflow: hidden;  
    }
    .submit-button{
        width: 400px;
        height: 40px;
        border-radius: 4vmax;
        border: 2px solid lightblue;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        position: absolute;
        bottom: 20px;
        display: grid;
        cursor:pointer;
        grid-template-columns: 150px 30px 1fr;
    }
    .submit-statement{
        text-align: center;
        margin:0px;
        margin-top: 9px;
        font-weight:bold;
    }
    .submit-pin{
        height: 20px;
        width: 20px;
        margin-top: 5px;
    }
    .submit-city{
        margin:0px;
        margin-top: 9px;
        font-weight:bold;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .close-button{
        font-size: 30px;
        font-weight: bold;
        color: lightblue;
        position: absolute;
        top: -30px;
        right: -45px;
        margin: 0px;
        cursor: pointer;
    }

    @media (max-width: 850px){
        .LocationButton{
            width: 90px;
            height: 40px;
            grid-template-columns: 25px 1fr;
        }
        .pin{
            height: 15px;
            width: 15px;
            margin-left: 10px;
        }
        .city{
            font-size: 9px;
        }
        .background{
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .background::-webkit-scrollbar{
            width: 10px;
            background-color: rgba(255,255,255,0.3);
        }
        .background::-webkit-scrollbar-thumb{
            width: 10px;
            background-color: rgba(255,255,255,0.7);
            border-radius: 4vmax;
        }
        .location-block{
            width: 500px;
            min-height:fit-content;
            margin-bottom: 20px;
        }
        .search-location{
            width: 400px;
            height: 40px;
        }
        .map{
            margin-top: 20px;
            margin-bottom: 20px;
            position: relative;
            bottom: 0px;
            left: 0px;
        }
        .results{
            position: relative;
            bottom: 0px;
            right: 0px;
            margin-bottom: 20px;
        }
        .submit-button{
            height: 40px;
            position: relative;
            margin-bottom: 20px;
            bottom: 0px;
        }
    }
    @media (max-width: 650px){
        .location-block{
            width: 400px;
        }
        .search-location{
            width: 350px;
        }
        .submit-button{
            width: 350px;
        }
        .close-button{
            top: -40px;
            right: -5px;
        }
    }
    @media (max-width: 450px){
        .background::-webkit-scrollbar{
            width: 0px
        }
        .background::-webkit-scrollbar-thumb{
            width: 0px
        }
        .location-block{
            width: 100%;
            height: 100%;
            margin: 0px;
            border-radius: 0px;
        }
        .search-location{
            width: 80%;
        }
        .submit-button{
            width: 80%;
            grid-template-columns: 100px 20px 1fr;
        }
        .submit-button p{
            font-size: 12px;
        }
        .submit-pin{
            width: 15px;
            height: 15px;
        }
        .close-button{
            top: 4px;
            right: 10px;
            color: black;
            font-size: 20px;
        }
        .results{
            width:80%;
        }
        h2{
            text-align: center;
        }
    }
}