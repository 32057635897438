
    .booking-spec1{
        background-color: white;
        height: 360px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 5px;
        margin-bottom: 5px;
        position: relative;
        display: grid;
        grid-template-rows: 45% 21.25% 21.25%;
        box-shadow: 0px 0px 2px 0px black;
    }
    .card-label{
        width: 100%;
    }
    .card-label h1{
        margin: 0px;
        width: 80%;
        margin-left: 30px;
        margin-top: 20px;
        height: 60px;
    }

    .typeSpec{
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .typeSpec h4{
        margin: 0px;
        margin-top: 5px;
        width: 80%;
        position: relative;
        left: 30px;
    }

    .type-option{
        display: grid;
        grid-template-columns: 16.667% 14.286% 52.380% 16.667%;
        width: 100%;
    }

    .type-option div{
        height: 13px;
        width: 13px;
        border-radius: 50%;
        border: 2px solid black;
        position: relative;
        top: 8px;
        left: 30px;
        cursor: pointer;
        box-shadow: 0px 0px 3px 0px black;
    }

    .type-option p{
        margin: 0px;
        margin-top: 8px;
    }

    .pic1{
        height: 35px;
        width: 40px;
    }

    .pic2{
        height: 35px;
        width: 40px;
    }

    .pic3{
        height: 40px;
        width: 45px;
    }

    .pic4{
        height: 40px;
        width: 65px;
        position: relative;
        left: -13px;
    }

    .interiorSpec{
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .interiorSpec h4{
        margin: 0px;
        margin-top: 5px;
        position: relative;
        left: 30px;
        width: 80%;
    }

    .interior-option{
        display: grid;
        grid-template-columns: 16.667% 66.666% 16.667%;
        width: 100%;
    }

    .interior-option div{
        height: 13px;
        width: 13px;
        border-radius: 50%;
        border: 2px solid black;
        position: relative;
        top: 8px;
        left: 30px;
        cursor: pointer;
        box-shadow: 0px 0px 3px 0px black;
    }

    .interior-option p{
        margin: 0px;
        margin-top: 8px;
    }

    .tyreSpec{
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .tyreSpec h4{
        margin: 0px;
        margin-top: 5px;
        position: relative;
        left: 30px;
        width: 80%;
    }

    .tyre-option{
        display: grid;
        grid-template-columns: 16.667% 66.666% 16.667%;
        width: 100%;
    }

    .tyre-option div{
        height: 13px;
        width: 13px;
        border-radius: 50%;
        border: 2px solid black;
        position: relative;
        top: 8px;
        left: 30px;
        cursor: pointer;
        box-shadow: 0px 0px 3px 0px black;
    }

    .tyre-option p{
        margin: 0px;
        margin-top: 8px;
    }

    @media (max-width:550px){
        .interior-option p{
            font-size: 12px;
            font-weight: bold;
        }
    }

    @media (max-width:400px){
        .tyre-option div,
        .interior-option div,
        .type-option div{
            left: 20px;
            width: 10px;
            height: 10px;
        }
        .tyre-option p,
        .interior-option p,
        .type-option p{
            font-size: 12px;
            margin-left: 5px;
        }
        .pic1{
            height: 25px;
            width: 30px;
        }
    
        .pic2{
            height: 25px;
            width: 30px;
        }
    
        .pic3{
            height: 30px;
            width: 35px;
        }
    }