h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
div,
button,
label,
input,
a,
Link{
    font-family: 'Times New Roman', Times, serif;
}
body{
    overflow-x: hidden;
}
body::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(14, 52, 65, 0.2);
}
body::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(14, 52, 65, 0.5);
    border-radius: 4vmax;
}
#Home{
    margin-top: 80px;
    width: 100dvw;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .block1{
        height: calc(100vh - 80px);
        background-color: lightblue;
        position: relative;
        display: flex;
        flex-direction: column;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 0.6fr;
        justify-items: center;
    }
    .block2{
        height: 520px;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr 1.6fr;
        position: relative;
    }
    
    .block3{
        height: 520px;
        background-color: white;
        display: grid;
        grid-template-columns: 1.6fr 1fr;
        position: relative;
    }
    
    .block1 h1{
        font-size: 70px;
        margin: 0px;
        text-align: center;
        position: relative;
        height: fit-content;
        margin-top: 20px;
    }
    
    .block1 button{
        background-color: white;
        width: 200px;
        height: 50px;
        border: solid 3px lightblue;
        border-radius: 100vmax;
        box-shadow: 0px 0px 3px 0px black;
        cursor: pointer;
        z-index: 10;
        position: relative;
    }
    
    .hText1,
    .hText3{
        color: black;
    }
    
    .hText2{
        color: white;
        transition: 2s
    }
    
    .home-frame2,
    .home-frame{
        height: 350px;
        width: 300px;
        border: solid 6px lightblue;
        border-radius: 1vmax;
        background-color: white;
        position: absolute;
        top: 85px;
    }
    .home-frame{
        right: 0px;
    }
    .home-frame2{
        left: 0px;
    }

    .l1{
        display: block;
    }
    .l2{
        display: none;
    }
    
    .image-block2,
    .image-block{
        position: relative;
    }
    
    .text-block2,
    .text-block{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .text-block2 h1,
    .text-block h1{
        margin-top: 10%;
        width: 500px;
        font-size: 45px;
        text-align: center;
        color: lightblue;
    }

    .text-block2 p,
    .text-block p{
        width: 500px;
        text-align: center;
        font-weight: bold;
    }

    .button-holder{
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: end;
    }
    .button-holder button{
        margin-top: 20px;
        background-color: white;
        width: 150px;
        height: 40px;
        border: solid 3px lightblue;
        border-radius: 100vmax;
        box-shadow: 0px 0px 3px 0px black;
        cursor: pointer;
    }
    .links{
        color: black;
        display: flex;
        flex-direction: row;
    }

    .signIn-text,
    .signIn-link{
        color: black;
        width: fit-content !important;
        margin: 0px;
        margin-top: 10px;
    }
    .space{
        width: 10px !important;
    }
    
    .block1 img{
        height: 100%;
        width: 100%;
        position: absolute;
        opacity: 0.7;
    }
    
    .home-frame2 img,
    .home-frame img{
        height: 100%;
        width: 100%;
        border-radius: 0.6vmax;
    }

    @media (max-width: 1000px){

        .block3,
        .block2{
            height: 900px;
            grid-template-columns: none;
            grid-template-rows: 0.4fr 1fr;
        }

        .block1 h1{
            font-size: 60px;
        }

        .image-block,
        .image-block2{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .l1{
            display: none;
        }
        .l2{
            display: block;
        }

        .home-frame2,
        .home-frame{
            position: relative;
            top: 0%;
            margin-top: 40px;
            height: 300px;
            width: 250px;
        }

    }

    @media (max-width: 550px){
        .block3,
        .block2{
            height: 800px;
            grid-template-columns: none;
            grid-template-rows: 0.4fr 1fr;
        }
        .block1 h1{
            font-size: 40px;
        }

        .text-block2 h1,
        .text-block h1{
            width: 80%;
            font-size: 25px;
        }

        .text-block2 a,
        .text-block a,
        .text-block2 p,
        .text-block p{
            width: 80%;
            font-size: 12px;
        }

        .button-holder{
            width: 80%;
        }
        .button-holder button{
            width: 120px;
            height: 40px;
            font-size: 12px;
        }

        .home-frame,
        .home-frame2{
            position: relative;
            top: 0%;
            margin-top: 40px;
            height: 250px;
            width: 200px;
        }

        .block1 button{
            background-color: white;
            width: 150px;
            height: 40px;
            font-size: 12px;
        }
        
    }
    @media (max-width:300px){
        .button-holder {
            align-items: center;
        }
        .home-frame,
        .home-frame2{
            position: relative;
            top: 0%;
            margin-top: 40px;
            height: 90vw;
            width: 60vw;
        }
    }
}

