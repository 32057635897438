#Directions{
    width: 100dvw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
    z-index: 110;
    overflow: hidden;

    .directions-block{
        width: 700px;
        height: 450px;
        border-radius: 1vmax;
        background-color: lightblue;
        align-items: center;
        position: relative;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .directions-block::-webkit-scrollbar{
        background-color: rgba(128, 128, 128, 0.6);
        width: 8px;
        border-top-right-radius: 1vmax;
        border-bottom-right-radius: 1vmax;
    }
    .directions-block::-webkit-scrollbar-thumb{
        background-color: rgba(0,0,0,0.7);
        border-radius: 4vmax;
    }
    .address-body{
        width: 80%;
        height: 250px;
        border: 4px solid lightblue;
        border-radius: 1vmax;
        box-shadow: 0px 0px 3px 0px black;
        background-color: white;
        margin-top: 40px;
        margin-bottom: 20px;
        position: relative;
        left: 10%;
    }
    .address-body p{
        color: gray;
        font-size: 13px;
        font-weight: bold;
        margin-left: 15px;
        margin-right: 5px;
    }
    .address-body span{
        color: black;
        font-weight: normal;
    }
    .side-by-side{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .map-body{
        background-color: grey;
        border: 5px solid lightblue;
        box-shadow: 0px 0px 3px 0px black;
        width: 80%;
        height: 80%;
        position: relative;
        left: 10%;
        margin-bottom: 20px;
    }
    .close-button{
        font-size: 25px;
        font-weight: bold;
        color: black;
        position: absolute;
        top: 5px;
        right: 10px;
        margin: 0px;
        cursor: pointer;
    }

    @media (max-width: 850px){    
        overflow-y: scroll;
        overflow-x: hidden;
        
        .directions-block{
            width: 500px;
        }

    }
    @media (max-width: 650px){
        .directions-block{
            width: 400px;
        }
    }
    @media (max-width: 450px){
        .directions-block{
            width: 100%;
            height: 100%;
            border-radius: 0vmax;
        }
        .close-button{
            top: 4px;
            right: 10px;
            color: black;
            font-size: 20px;
        }
        .address-body p{
            color: gray;
            font-size: 11px;
            /* font-weight: bold;
            margin-left: 15px;
            margin-right: 5px; */
        }
    }
}

@media (max-width: 850px){    
    
    #Directions::-webkit-scrollbar{
        width: 10px;
        background-color: rgba(255,255,255,0.3);
    }
    #Directions::-webkit-scrollbar-thumb{
        width: 10px;
        background-color: rgba(255,255,255,0.7);
        border-radius: 4vmax;
    }
}
@media (max-width: 450px){
    #Directions::-webkit-scrollbar{
        width: 0px
    }
    #Directions::-webkit-scrollbar-thumb{
        width: 0px
    }
}