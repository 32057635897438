#resetPasswordPage{
    display: grid;
    grid-template-rows: 67px 3px 1fr;

    .logo-block{
        width: 100%;
        height: 100%;
        background-color: lightblue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .logo-block img{
        width: 200px;
        height: 100%px;
    }
    
    .Underliner{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .Line1{
        background-color: lightblue;
    }
    
    .Line2{
        background-color: lightblue;
    }
    
    form{
        width: 100%;
        height: 533px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
    }
    h2{
        text-align: center;
        position: relative;
        margin-top: 50px;
    }

    .password1,
    .password2{
        margin-top: 30px;
        width: 500px;
        height: 80px;
        position: relative;
    }
    
    .password1 input,
    .password2 input{
        width: 100%;
        height: 60px;
        position: relative;
        border-radius: 100vmax;
        background-color: transparent;
    }

    .password1 a{
        position: absolute;
        right: 0px;
        color: black;
        margin-top: 3px;
    }
    
    .password1Placeholder,
    .password2Placeholder{
        position: absolute;
        top: 20px;
        left: 10%;
        font-size: 20px; 
        transition: 1s ease;  
    }
    
    .password1Placeholder.active,
    .password2Placeholder.active{
        top: -20px;
        font-size: 17px;
        background-color: white; 
    }
    
    .link{
        width: 500px;
        position: relative;
        margin-top: 10px;
    }
    .link div{
        display: flex;
        flex-direction: row;
        width: fit-content;
        position: absolute;
        right: 0px;
    }
    .link p{
        margin: 0px;
        margin-right: 10px;
    }
    .link a{
        color: black;
    }
    .send{
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-top: 20px;
    }
    .send button{
        height: 40px;
        width: 200px;
        background-color: black;
        border-radius: 100vmax;
        color: white;
    }
    
    .required{
        position: absolute;
        top: -40px;
        right: 0px;
        color: red;
    }
    .rp-popup{
        position: fixed;
        height: 150px;
        width: 130px;
        padding: 5px;
        box-shadow: 0px 0px 10px 0px darkblue;
        top: 30vh;
        left: 45vw;
        z-index: 100;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .popup-message{
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }
    .popup-closer{
        position: absolute;
        font-weight: bold;
        top: -40px;
        right: -20px;
        cursor: pointer;
    }

    @media(max-width: 550px){
        h2{
            font-size: 20px;
        }

        .link,
        .send,
        .password1,
        .password2{
            width: 90%;
        }
        .send button{
            height: 35px;
            width: 160px;
            background-color: black;
            border-radius: 100vmax;
            color: white;
        }
        .rp-popup{
            left: 35vw;
        }
    }
    @media(max-width: 450px){
        .send{
            align-items: center;
        }
        .rp-popup{
            left: 30vw;
        }
    }
}