#ContactUs{
    width: 100dvw;
    min-height: 100vh;
    background-color: rgba(173, 216, 230, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-block{
        width: 100%;
        height: 70px;
        background-color: lightblue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .logo-block img{
        width: 200px;
        height: 100%;
    }
    .backButton{
        width: 80px;
        height: 25px;
        border-radius: 4vmax;
        box-shadow: 0px 0px 2px 0px black;
        position: absolute;
        left: 10px;
        top: 100px;
        border: none;
        background-color: lightblue;
        cursor: pointer;
        z-index: 10;
    }
    .document{
        width: 450px;
        height: 420px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
        margin-top: 40px;
        margin-bottom: 100px;
        padding: 20px;
        position: relative;
    }

    /* p, */
    .title{
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
        z-index: 2;
        position: relative;
    }
    .title{
        font-size: 40px;
    }
    p{
        font-size: 15px;
        z-index: 2;
        position: relative;
        text-align: center;
        margin-bottom: 17px;
        /* background-color: antiquewhite; */
    }
    .number,
    .address,
    .email{
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        width: 100%;
        z-index: 2;
        position: relative;
        /* background-color: aquamarine; */
    }

    .theme{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
    }
    @media (max-width:500px){
        .document{
            width: 90%;
            height: calc(100vh-70px);
            box-shadow: none;
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 5%;
            padding-bottom: 5%;
            position: relative;
        }
        .theme{
            width: 100%;
            height: 100%;
        }
        .title{
            margin-top: 30px;
            font-size: 30px;
        }
        p{
            font-size: 12px;
            font-weight: bold;
        }
        .email,
        .address,
        .number{
            font-size: 20px;
        }
    }
    @media (max-width:400px){
        .title{
            font-size: 30px;
        }
        .number,
        .address,
        .email{
            font-size: 17px;
        }
    }
}