#Account{
    margin-top: 80px;
    width: 100dvw;
    min-height: 593px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .userdata-block{
        height: 180px;
        width: 820px;
        background-color: white;
        border: solid 3px lightblue;
        border-radius: 1vmax;
        margin-top: 10px;
        position: relative;
        z-index: 4;
        display: grid;
        grid-template-columns: 150px 1fr;
        overflow: hidden;
    }
    .right-block{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 10px;
    }
    
    .selection-block2,
    .selection-block1{
        width: 680px;
        height: 40px;
        background-color: white;
        border-radius: 100vmax;
        z-index: 5;
        margin-top: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .selection-block2 div,
    .selection-block1 div{
        height: 40px;
        background-color: rgb(95, 152, 167);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
      
    .selection-block1 p{
        color: white;
        margin: 0px;
        margin-top: 6px;
        text-align: center;
    }
    
    .selection-block2 img{
        height: 25px;
        width: 25px;
        margin-top: 7px;
    }
       
    .selection1{
        border-top-left-radius: 100vmax;
        border-bottom-left-radius: 100vmax;
        background-color: rgb(95, 152, 167);
    }
       
    .selection2{
        background-color: rgb(95, 152, 167);
    }
       
    .selection3{
        border-top-right-radius: 100vmax;
        border-bottom-right-radius: 100vmax;
        background-color: rgb(95, 152, 167);
    }
    
    .profile{
        min-height: 500px;
        width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account-slideshow{
        height: 250px;
        width: 100dvw;
        margin: 0px;
        position: absolute;
        bottom: 45px;
        display: grid;
        grid-template-columns: 10% 1fr 10%;
    }
    
    
    .account-frame{
        height: 90px;
        width: 90px;
        border: solid 3px lightblue;
        border-radius: 50%;
        background-color: white;
        position: relative;
        background-color: rgb(53, 103, 119);
        margin-left: 25px;
        margin-top: 10px;
    }
    .user-image{
        object-fit: contain;
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
    
    .account-frame2{
        height: 150px;
        width: 150px;
        border: solid 6px lightblue;
        border-radius: 50%;
        background-color: rgb(53, 103, 119);
        margin-top: 20px;
        position: relative;
    }
    .af-img{
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    
    .userData{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 47dvw;
        position: absolute;
        top: 15%;
        left: 21%;
    }
    
    .userdata-block button{
        background-color: white;
        height: 40px;
        width: 100px;
        border: solid 3px lightblue;
        border-radius: 1vmax;
        margin-top: 10px;
        margin-left: 20px;
    }
    
    .edit-user{
        height: 25px;
        width: 25px;
        position: absolute;
        bottom: 5px;
        right: -30px;
        cursor: pointer;
    }
    
    .edit-servicer{
        height: 25px;
        width: 25px;
        position: absolute;
        bottom: 10px;
        right: -40px;
        cursor: pointer;
    }
    
    .location-pin{
        height: 15px;
        width: 15px;
        position: absolute;
        top: 23%;
        left: 19%;
    }
    
    .slide-block1,
    .slide-block3{
        position: relative;
        display:flex;
        flex-direction: column;
    }
    
    .slide-block2{
        overflow: hidden;
        position: relative;
    }
    
    .slide-block1 div,
    .slide-block3 div{
        height: 35px;
        width: 35px;
        background-color: rgba(0,0,0,0.8);
        border-radius: 50%;
        position: absolute;
        top: 40%;
    }
       
    .slide-block1 div{
        right: 0px;
        cursor: pointer;
    }
    .slide-block3 div{
        left: 0px;
        cursor: pointer;
    }


    .slide-block1 img,
    .slide-block3 img{
        height: 25px;
        width: 25px;
        position: relative;
        top: 5px;
        left: 5px;
    }
    
    .service-type{
        width: 100dvw;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }
    
    
    .dot1{
        position: relative;
        top: -4px;
        left: -5px;
    }
    .dot2{
        position: relative;
        top: -4px;
        left: 5px;
    }
    
    .stars-horizontal{
        width: 240px;
        height: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    
    .stars-horizontal img{
        height: 40px;
        width: 40px;
    }
    
    .servicerData{
        height: 30px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1.2fr;
    }
    
    .jobs-tally{
        text-align: right;
        width: 100%;
        font-weight: bold;
        margin-right: 25px;
    }
    .location{
        margin-left: 25px;
        display: flex;
        flex-direction: row;
    }
    .location p{
        text-align: left;
        width: 85%;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .location img{
        height: 20px;
        width: 20px;
        position: relative;
        top: 12px;
    }
    
    .servicer-name{
        width: 100%;
        text-align: center;
    }
    
    .servicer-buttons{
        display: flex;
        flex-direction: row;
        position: relative;
    }
    .servicer-buttons button{
        height: 50px;
        width: 100px;
        border-radius: 0.5vmax;
        border: solid 4px lightblue;
        background-color: white;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 5px;
    }
    
    .save-button{
        left: 40dvw;
    }
    .servicer-signout{
        left: 30dvw;
    }

    .book-button{
        left: 52dvw;
    }
    .servicer-schedule{
        left: 41dvw;
    }
    
    .message-button{
        left: 57dvw;
    }
    .servicer-bookings{
        left: 52dvw;
    }
    .servicer-message {
        left: 63dvw;
    }
    
    .servicer-description{
        width: 100%;
        min-height: 130px;
        font-size: 20px;
        text-align: center;
        word-break: break-word;
    }
    .image-grid{
        width: 80%;
        min-height:490px;
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .video-grid{
        width: 80%;
        min-height:490px;
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .plus{
        height: 50px;
        width: 50px;
        border-radius: 100%;
        background-color: rgb(95, 152, 167);
        box-shadow: 0px 0px 7px 0px darkblue;
        position: fixed;
        bottom: 3vh;
        right: 3dvw;
        cursor: pointer;
        z-index: 10;
    }
    .plus img{
        height: 30px;
        width: 30px;
        position: relative;
        top: 20%;
        left: 19%;
    }

    .user-name{
        font-weight: bold;
        width: 97%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .user-email{
        font-weight: bold;
        width: 97%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .saved-notifier,
    .saved-decliner{
        box-shadow: 0px 0px 10px 0px lightblue;
        height: 100px;
        width: 120px;
        background-color: white;
        font: black;
        text-align: center;
        position: absolute;
        top: -120px;
        left: 52px;
        align-items:center;
    }

    @media (max-width: 900px){
        .right-block{
            display: grid;
            grid-template-columns: none;
            grid-template-rows: 1fr 1fr 1fr;
            margin-top: 10px;
        }
        .user-name{
            margin: 0px;
            position: relative;
            left: 30px;
            width: 90%;
        }
        .user-email{
            margin: 0px;
            position: relative;
            left: 30px;
            width: 90%;
        }
    }
    @media (max-width: 850px){
        .userdata-block{
            height: 180px;
            width: 95%;
            background-color: white;
            border: solid 3px lightblue;
            border-radius: 1vmax;
            margin-top: 10px;
            position: relative;
            z-index: 4;
        }
    }
    @media (max-width: 750px){
        .image-grid{
            width: 85%;
            min-height:490px;
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .video-grid{
            width: 85%;
            min-height:490px;
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (max-width:700px){
        .selection-block2,
        .selection-block1{
            width: 95%;
            height: 40px;
            background-color: white;
            border-radius: 100vmax;
            z-index: 5;
            margin-top: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    @media (max-width: 610px){
        .profile{
            min-height: 500px;
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    @media (max-width: 550px){
        .account-slideshow{
            height: 250px;
            width: 100dvw;
            margin: 0px;
            position: absolute;
            bottom: 45px;
            display: grid;
            grid-template-columns: 0% 1fr 0%;
        }
        .slide-block1 div{
            right: -50px;
            z-index: 10;
        }
        .slide-block3 div{
            left: -50px;
            z-index: 10;
        }
        .servicer-buttons button{
            height: 40px;
            width: 80px;
            margin-left: 3px;
            margin-right: 3px;
            font-size: 12px;
            font-weight: bold;
        }
        .servicer-description{
            font-size: 15px;
        }
        .servicer-name{
            font-size: 25px;
        }

        .saved-notifier,
        .saved-decliner{
            left: 35px;
            font-size: 13px;
            width: 100px;
            height: 80px;
            top: -95px;
        }
    }
    @media (max-width: 500px){

        .selection-block2 p,
        .selection-block1 p{
            font-size: 12px;
        }
        .image-grid{
            width: 85%;
            min-height: 490px;
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr;
        }
        .video-grid{
            width: 85%;
            min-height:490px;
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr;
        }
        .profile{
            width: 85%;
        }
        .stars-horizontal{
            width: 90%;
            height: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            justify-items: center;
        }
        
        .stars-horizontal img{
            height: 30px;
            width: 30px;
        }
        .servicerData{
            justify-items: center;
        }
        .account-frame{
            width: 70px;
            height: 70px;
        }
        .left-block button{
            width: 75px;
        }
        .userdata-block{
            grid-template-columns: 110px 1fr;
        }
        .location p{
            font-size: 12px;
        }
        .user-name,
        .user-email{
            font-size: 12px;
            width: 85%;
        }
    }

    @media(max-width:400px){
        .servicerData p{
            font-size: 12px;
        }
        .location img{
            height: 15px;
            width: 15px;
            position: relative;
            top: 10px;
        }
        .servicer-buttons button{
            height: 30px;
            width: 62px;
            margin-left: 2px;
            margin-right: 2px;
            font-size: 10px;
            border: solid 3px lightblue;
        }
        .servicer-name{
            font-size: 20px;
        }
    }
}

