#emailPage{
    display: grid;
    grid-template-rows: 67px 3px 1fr;
    justify-items: center;

    .logo-block{
        width: 100%;
        height: 100%;
        background-color: lightblue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .logo-block img{
        width: 200px;
        height: 100%;
    }
    
    .emailUnderliner{
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* box-shadow: 0px 0px 1px 0px black; */
    }
    
    .euLine1{
        background-color: lightblue;
    }
    
    .euLine2{
        background-color: lightblue;
    }
    
    form{
        width: 500px;
        background-color: transparent;
        height: 533px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        margin-top: 40px;
    }

    h2{
        text-align: center;
        position: relative;
        margin-top: 50px;
        width: 90%;
    }

    .email{
        margin-top: 30px;
        width: 100%;
        height: 80px;
        position: relative;
    }
    .email input{
        width: 100%;
        height: 60px;
        position: relative;
        border-radius: 100vmax;
        background-color: transparent;
    }
        
    .submit{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-top: 40px;
    }
    .submit button{
        height: 40px;
        width: 200px;
        background-color: black;
        border-radius: 100vmax;
        color: white;
    }

    .emailPlaceholder{
        position: absolute;
        top: 20px;
        left: 10%;
        font-size: 20px; 
        transition: 1s ease;  
    }
    
    .emailPlaceholder.active{
        top: -20px;
        font-size: 17px;
        background-color: white; 
    }
    .link{
        width: 100%;
        position: relative;
        margin-top: 10px;
        height: 10px;
    }
    .link div{
        display: flex;
        flex-direction: row;
        width: fit-content;
        position: absolute;
        left: 0px;
    }
    .link p{
        margin: 0px;
        margin-right: 10px;
        margin-left: 10px;
        font-size: 13px;
    }
    .link a{
        color: black;
        font-size: 13px;
    }
    
    .required{
        position: absolute;
        top: -25px;
        right: 0px;
        color: red;
        margin: 0px;
    }

    @media(max-width: 550px){
        h2{
            font-size: 18px;
        }

        form{
            width: 90%;
        }

        .link a,
        .link p{
            font-size: 12px !important;
        }

        .emailPlaceholder{
            font-size: 15px; 
        }

        .submit button{
            height: 35px;
            width: 150px;
        }
    }
    @media(max-width: 450px){
        .pageHeader{
            font-size: 30px;
            font-family: 'Great Vibes', cursive;
            color: white;
        }

    }
    @media(max-width: 255px){
        .link a,
        .link p{
            font-size: 10px !important;
            font-weight: bold;
        }
    }
}

