#Checkout{
    display: grid;
    margin-top: 80px;
    width: 100%;
    grid-template-columns: 1fr 36.5dvw;
    overflow: hidden;
    .addCard-link{
        text-decoration: underline;
        width: fit-content;
        margin-left: 12px;
        cursor: pointer;
    }

    .settings{
        background-color: white;
        min-height: 533px;
    }

    .page-title{
        width: fit-content;
        font-size: 50px;
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: 70px;
    }

    .icon-block{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
    .no-checkout{
        width: 40%;
        margin-left: 0%;
    }

    .address-block{
        height: 300px;
        width: 83%;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        margin-bottom: 40px;
        margin-left: 8.5%;
        display: grid;
        grid-template-rows: 50px 1fr;
    }

    .payment-block{
        height: 200px;
        width: 83%;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        margin-bottom: 40px;
        margin-left: 8.5%;
        display: grid;
        grid-template-rows: 50px 1fr;
        position: relative;
    }

    .block-title{
        width: 100%;
        border-top-left-radius: 1vmax;
        border-top-right-radius: 1vmax;
        border-bottom: solid 1px grey;
    }
    .block-title h3{
        margin-top: 15px;
        margin-left: 10px;
        width: fit-content;
    }

    .address-body{
        display: grid;
        grid-template-columns: 300px 1fr;
    }

    .map-block{
        width: 200px;
        height: 200px;
        margin-left: 50px;
        margin-top: 25px;
        background-color: gray;
        box-shadow: 0px 0px 2px 0px black;
        z-index: 13;
        position: relative;
    }

    .address-settings{
        height: 250px;
        width: 100%;
        /* overflow-x: hidden;
        overflow-y: scroll; */
        overflow: hidden;
        border-bottom-right-radius: 1vmax;
        position: relative;
    }
    .address-settings::-webkit-scrollbar{
        width: 5px;
        background-color: rgba(0px, 0px, 0px, 0.5);
    }
    .address-settings::-webkit-scrollbar-thumb{
        border-radius: 4vmax;
        background-color: rgba(0px, 0px, 0px, 0.9);
    }

    .building-type{
        display: grid;
        width: 75%;
        min-height: 40px;
        position: absolute;
        top: 20px;
        left: 10%;
        border-bottom: solid 2px gray;
    }
    .building-type img{
        z-index: 13;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 16px;
        right: 10px;
        cursor: pointer;
    }
    .selected-btype{
        z-index: 13;
        margin: 0px;
        font-size: 15px;
        color: gray;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: absolute;
        top: 16px;
        left: 7px;
    }

    .required-tag{
        margin: 0px;
        font-size: 12px;
        font-weight: bold;
        color: red;
        position: absolute;
        top: -5px;
        right: 0px;
    }

    .Num-input{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border: 0px;
        outline: none;
        border-bottom: solid 2px gray;
        background-color: transparent;
    }
    .Name-input{
        z-index: 10;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border: 0px;
        outline: none;
        border-bottom: solid 2px gray;
        background-color: transparent;
    }
    .Note-input{
        z-index: 10;
        width: 73.5%;
        height: 30px;
        position: absolute;
        left: 10%;
        border: 0px;
        outline: none;
        border-bottom: solid 2px gray;
        background-color: transparent;
    }
    .Estate-input{
        z-index: 10;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border: 0px;
        outline: none;
        border-bottom: solid 2px gray;
        background-color: transparent;
    }

    .Num-carrier{
        z-index: 10;
        width: 15%;
        height: 30px;
        position: absolute;
        left: 10%;
        /* background-color: pink; */
    }
    .Name-carrier{
        z-index: 10;
        width: 53%;
        height: 30px;
        position: absolute;
        left: 31%;
        /* background-color: pink; */
    }
    .Estate-carrier{
        z-index: 10;
        width: 73.5%;
        height: 30px;
        position: absolute;
        left: 10%;
        /* background-color: pink; */
    }

    .types{
        background-color: white;
        width: 100%;
        transition: 2s ease-in-out;
        z-index: 12;
        overflow: hidden;
    }
    .type-card{
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 2.5%;
        position: relative;
        top: 50px;
        height: 25px;
        width: 95%;
        text-align: center;
        background-color: white;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        cursor: pointer;
    }
    .payment-options{
        margin-top:10px;
        margin-bottom: 10px;
        margin-left: 7.5%;
        width: 85%;
        height: 50px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px 0px black;
        display:grid;
        grid-template-columns: 1fr 1fr;
    }
    .payment-options div{
        width: 100%;
        height: 100%;
        border: 0px;
        box-shadow: 0px 0px 2px 0px black;
        margin: 0px
    }
    .payment-options p{
        text-align: center;
    }
    .cash{
        border-top-right-radius: 0.5vmax;
        border-bottom-right-radius: 0.5vmax;
        cursor: pointer;
    }
    .card{
        border-top-left-radius: 0.5vmax;
        border-bottom-left-radius: 0.5vmax;
        cursor: pointer;
    }
    .selected-option{
        background-color: rgb(105, 153, 168);
        color: white;
    }
    .card-options{
        width: 85%;
        margin-left: 7.5%;
        height: 70px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px 0px black;
        display: grid;
        grid-template-columns: 70px 1fr;
        position: relative;
    }
    .payment-disclaimer{
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        left: 0px;
        margin: 0px;
    }
    .logo-frame{
        width: 20px;
        height: 15px;
        background-color: white;
        margin: 25px;
        margin-top: 29px;
    }
    .logo-frame img{
        width: 100%;
        height: 100%;
    }
    .card-name {
        position: relative;
        left: 0px;
        top: 10px;
        display: flex;
        flex-direction: row;
    }
    .card-name p{
        margin: 0px;
        width: fit-content;
        height: fit-content;
    }
    .card-name img{
        height: 17px;
        width: 17px;
        margin-left: 5px;
    }
    .card-details{
        position: relative;
        display: flex;
        flex-direction: row;
        left: 0px;
    }
    .card-details p{
        margin: 0px;
        margin-top: 30px;
        width: fit-content;
        font-size: 14px;
        font-weight: bold;
    }
    .card-expiry{
        margin-left: 70px !important;
    }
    .arrow{
        width: 25px;
        height: 25px;
        position: absolute;
        right: 20px;
        top: 22.5px;
        cursor: pointer;
    }
    .card-list{
        width: 320px;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 0.5vmax;
        position: absolute;
        top: 0px;
        left: 5%;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: white;
        transition: 2s ease-in-out;
    }
    .card-list::-webkit-scrollbar{
        width: 6px;
        background-color: rgba(0,0,0,0.3);
    }
    .card-list::-webkit-scrollbar-thumb{
        border-radius: 4vmax;
        background-color: rgba(0,0,0,0.9);
    }
    .card-option{
        position: relative;
    }
    .card-data{
        height: 70px;
        width: 300px;
        margin: 10px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px 0px black;
        display: grid;
        grid-template-columns: 70px 1fr;
        cursor: pointer;
        position: relative;
    }
    .card-delete{
        height: 20px;
        width: 17px;
        position: absolute;
        right: 10px;
        top: 25px;
        cursor: pointer;
    }
    .checkout-data{
        background-color: lightblue;
        height: 100%;
        width: 100%;
        position: sticky;
        right: 0px;
        display: grid;
        grid-template-rows: 2fr 4.5fr 1.5fr 2.5fr;
    }
    .block1{
        width: 100%;
        position: relative;
        display: grid;
        grid-template-rows: 40px 1fr;
    }
    .data-holder{
        display: grid;
        grid-template-columns: 1fr 4fr;
    }
    .servicer-image{
        display: flex;
        flex-direction: column;
        align-items: end;
    }
    .servicer-data{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .block2{
        width: 80%;
        height: 300px;
        margin-left:10%;
        border-bottom: solid 2px gray;
        border-top: solid 2px gray;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .block2::-webkit-scrollbar{
        background-color: rgba(0,0,0,0.3);
        width: 7px;
    }
    .block2::-webkit-scrollbar-thumb{
        background-color: rgba(0,0,0,0.9);
        border-radius: 4vmax;
    }
    .block3{
        width: 80%;
        margin-left:10%;
        border-bottom: solid 2px gray;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .block4{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-frame{
        position: relative;
        top: 10px;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        border: 3px solid white;
        box-shadow: 0px 0px 1px 0px black;
        background-color: rgb(53, 103, 119);
    }
    .profile-frame img{
        object-fit: contain;
        border-radius: 100%;
        width: 100%;
        height: 100%;
    }
    .booking-name{
        width: 95%;
        white-space: nowrap;          
        overflow: hidden;             
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
    }
    .booking-surname{
        width: 95%;
        white-space: nowrap;          
        overflow: hidden;             
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        margin-top: 0px;
    }
    .rating{
        display: flex;
        flex-direction: row;
    }
    .rating p{
        width:fit-content;
        font-size: 13px;
        font-weight: bold;
    }
    .rating img{
        height: 25px;
        width: 25px;
        margin-left: 3px;
    }
    .booking-jobs{
        width: fit-content;
        font-size: 13px;
        font-weight: bold;
        margin-top: 0px;
    }
    .booking-statement{
        width: 100%;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
    }
    .pay-button{
        width: 200px;
        height: 40px;
        border-radius: 4vmax;
        position: absolute;
        top: 50px;
        background-color: black;
        color: white;
        cursor: pointer;
    }
    .cancel-button{
        width: 100px;
        height: 25px;
        border-radius: 4vmax;
        position: absolute;
        top: 110px;
        cursor: pointer;
    }

    .b3-right,
    .b3-left{
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .b3-left p{
        font-size: 15px;
        font-weight: bold;
        margin: 0px;
        margin-top: 7px;
        margin-left: 15px;
    }
    .b3-right p{
        font-size: 15px;
        margin: 0px;
        margin-top: 7px;
        width: 100%;
        text-align: center;
    }

    .car-card,
    .pool-card,
    .garden-card,
    .house-card,
    .slot-card{
        width: 90%;
        height: 90px;
        margin-left: 5%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px;
        background-color: white;
        position: relative;
    }
    .room-card{
        width: 90%;
        height: 50px;
        margin-left: 5%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px;
        background-color: white;
        position: relative;
    }
    .bathroom-card{
        width: 90%;
        height: 50px;
        margin-left: 5%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px;
        background-color: white;
        position: relative;
    }

    .cc-name,
    .gc-name,
    .pc-name,
    .hc-name,
    .sc-name{
        font-size: 15px;
        font-weight: bold;
        position: absolute;
        top: 5px;
        left: 10px;
    }

    .cc-fee,
    .gc-fee,
    .pc-fee,
    .hc-fee,
    .sc-fee{
        font-size: 15px;
        font-weight: bold;
        position: absolute;
        top: 5px;
        right: 10px;
    }

    .sc-period{
        position: absolute;
        font-size: 12px;
        font-weight: bold;
        bottom: 0px;
        left: 10px;
    }

    .sc-date{
        position: absolute;
        font-size: 12px;
        font-weight: bold;
        bottom: 0px;
        left: 80px;
    }

    .extra{
        color: green;
        font-size: 10px;
        font-weight: bold;
        margin: 0px;
    }
    .extras-block{
        width: 130px;
        height: 40px;
        position: absolute;
        bottom:5px;
        left: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .rc-name{
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        left: 10px;
    }
    .rc-fee{
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        right: 10px;
    }
    .room-windows{
        font-size: 10px;
        font-weight: bold;
        color: green;
        position: absolute;
        top: 20px;
        left: 10px;
    }
    .bc-name{
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        left: 10px;
    }
    .bc-fee{
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        right: 10px;
    }
    .bathroom-windows{
        font-size: 10px;
        font-weight: bold;
        color: green;
        position: absolute;
        top: 20px;
        left: 10px;
    }
    .checkout-popup{
        position: fixed;
        height: 150px;
        width: 130px;
        padding: 5px;
        box-shadow: 0px 0px 10px 0px darkblue;
        top: 50vh;
        left: 45vw;
        z-index: 100;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .popup-message{
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }
    .popup-closer{
        position: absolute;
        font-weight: bold;
        top: -40px;
        right: -20px;
        cursor: pointer;
    }
    
    @media (max-width: 1000px){
        display: flex;
        flex-direction: column;

        .checkout-data{
            position: relative;
            right: 0px;
        }
    }
    @media (max-width: 650px){
        .address-block{
            width: 85%;
            margin-left: 7.5%;
        }
    
        .payment-block{
            width: 85%;
            margin-left: 7.5%;
        }
    }
    @media (max-width: 600px){
        .map-block{
            width: 150px;
            height: 150px;
            margin-left: 25px;
            margin-top: 50px;
            background-color: gray;
            box-shadow: 0px 0px 2px 0px black;
            z-index: 13;
            position: relative;
        }
        .address-body{
            grid-template-columns: 200px 1fr;
        }
        .payment-disclaimer{
            font-size: 17px;
        }
        .checkout-popup{
            left: 40vw;
        }
    }
    @media (max-width: 500px){
        .card-data{
            height: 70px;
            width: 95%;
            margin: 0px;
            margin-left: 2.5%;
            margin-top: 10px;
            margin-bottom: 10px;
            grid-template-columns: 60px 1fr;
        }
        .page-title{
            margin: 0px;
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            width: 100%;
        }
        .logo-frame{
            width: 20px;
            height: 12px;
            margin: 0px;
            margin-left: 25px;
            margin-top: 15px;
        }
        .card-details p{
            margin-top: 18px;
        }
        .card-details{
            flex-direction: column;
        }
        .card-expiry{
            margin-left: 0px !important;
            margin-top: 6px !important;
        }
        .map-block{
            width: 70%;
            height: 130px;
            margin-left: 15%;
            margin-top: 20px;
            background-color: gray;
            box-shadow: 0px 0px 2px 0px black;
            z-index: 13;
            position: relative;
        }
        .address-body{
            grid-template-columns:none;
            grid-template-rows: 140px 1fr;
        }
        .address-block{
            height: fit-content;    
        }
    
        h1,
        h3{
            font-size: 15px;
            font-weight: bold;
        }

        input,
        p{
            font-size: 12px !important;
            font-weight: bold !important;
        }
        .arrow{
            height: 20px;
            width: 20px;
        }
        .card-list{
            width: 70%;
        }
        .checkout-popup{
            left: 30vw;
        }
    }
}