#userDataPage{
    display: grid;
    grid-template-rows: 67px 3px 1fr;

    .logo-block{
        width: 100%;
        height: 100%;
        background-color: lightblue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .logo-block img{
        width: 200px;
        height: 100%px;
    }
    
    .emailUnderliner{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .euLine1{
        background-color: lightblue;
    }
    
    .euLine2{
        background-color: lightblue;
    }

    .userInputPart{
        position: relative;
    }
    
    .WelcomeTxt{
        font-size: 35px;
        width: 90%;
        position: relative;
        left: 5%;
        text-align: center;
        margin-top: 60px;
    }
    
    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 700px;
    }

    .code,
    .name-surname,
    .password,
    .password2,
    .number,
    .promotions,
    .terms,
    .account-type,
    .submit{
        width: 500px;
        height: 40px;
        margin-top: 30px;
        position: relative;
    }

    .name,
    .surname{
        position: relative;
    }

    .name-surname{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .promotions,
    .terms{
        display: flex;
        flex-direction: row;
    }
    .promotions p,
    .terms p{
        margin: 0px;
        margin-left: 5px;
    }
    .promotions a,
    .terms a{
        color: black;
    }

    .emailCodeHeader{
        font-weight: bold;
        font-size: 22px;
        margin: 0px;
        text-align: center;
        width: 90%;
    }
    
    .code input,
    .password input,
    .password2 input,
    .number input{
        width: 100%;
        height: 40px;
        border-radius: 100vmax; 
    }

    .name input,
    .surname input{
        width: 95%;
        height: 40px;
        border-radius: 100vmax; 
    }
    .surname input{
        margin-left: 5px;
    }
        
    .terms input,
    .promotions input{
        height: 16px;
        width: 16px;
    }
                
    .submit{
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .submit button{
        height: 40px;
        width: 200px;
        background-color: black;
        border-radius: 100vmax;
        color: white;
    }
    
    .codePlaceholder,
    .namePlaceholder,
    .passwordPlaceholder,
    .password2Placeholder,
    .numberPlaceholder{
        position: absolute;
        top: 15px;
        left: 10%;
        font-size: 18px; 
        transition: 1s ease;  
    }

    .surnamePlaceholder{
        position: absolute;
        top: 15px;
        left: 15%;
        font-size: 18px; 
        transition: 1s ease; 
    }
    
    .codePlaceholder.active,
    .passwordPlaceholder.active,
    .password2Placeholder.active,
    .numberPlaceholder.active{
        top: -20px;
        left: 5%;
        font-size: 17px;
        background-color: white; 
    }
    .namePlaceholder.active,
    .surnamePlaceholder.active{
        top: -20px;
        font-size: 17px;
        background-color: white; 
    }
    .required{
        position: absolute;
        top: -36px;
        right: 0px;
        color: red; 
    }
    .required1{
        position: absolute;
        top: -36px;
        left: 100px;
        color: red; 
    }
    .required2{
        position: absolute;
        top: -32px;
        right: 0px;
        color: red; 
    }
        
    .accountInput{
        width: 120px;
        height: 40px;
        border: 2px solid;
        border-top-color: black;
        border-bottom-color:rgb(118, 118, 118) ;
        border-right-color: rgb(118, 118, 118);
        border-left-color: black;
        border-radius: 100vmax;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .userOption{
        border-right: 1px solid rgb(118, 118, 118);
        height: 40px;
        border-top-left-radius: 100vmax;
        border-bottom-left-radius: 100vmax;
        cursor: pointer;
    }
    
    .sellerOption{
        border-left: 1px solid rgb(118, 118, 118);
        height: 40px;
        border-top-right-radius: 100vmax;
        border-bottom-right-radius: 100vmax;
        cursor: pointer;
    }
    
    .userOption p{
        margin:0px;
        text-align: center;
        position: relative;
        top: 10px;
    }
    
    .sellerOption p{
        margin:0px;
        text-align: center;
        position: relative;
        top: 10px;
    }
        
    @media (max-width: 550px){
        .code,
        .name-surname,
        .password,
        .password2,
        .number,
        .promotions,
        .terms,
        .account-type,
        .submit{
            width: 90%;
        }

        .WelcomeTxt{
            font-size: 18px;
        }
        .emailCodeHeader{
            font-size: 12px;
        }
        .code input,
        .password input,
        .password2 input,
        .number input
        .name input,
        .surname input{
            height: 35px; 
        }
        label{
            font-size: 14px !important;
        }
        label.active{
            font-size: 12px !important;
        }

        .required,
        .required1,
        .required2{
            font-size: 15px !important;
        }

        .promotions p,
        .terms p,
        .terms a{
            margin-top: 5px;
            font-size: 12px;
        }

        .submit{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .submit button{
            height: 35px;
            width: 150px;
            background-color: black;
            border-radius: 100vmax;
            color: white;
        }
    }
    @media (max-width: 450px){
        .pageHeader{
            font-size: 30px;
            font-family: 'Great Vibes', cursive;
            color: white;
        }
    }
}

