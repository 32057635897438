#RatingCard{
    margin-bottom: 5px;
    box-shadow: 0px 0px 2px 0px black;
    width: 100%;
    min-height: 150px;
    display: grid;
    grid-template-rows: 50px 1fr;

    .rating-profile{
        display: grid;
        grid-template-columns: 50px 1fr;
    }
    .rating-data{
        display: grid;
        grid-template-rows: 25px 1fr;
        align-items: center;
    }
    .imgFrame{
        width: 35px;
        height: 35px;
        border-radius: 100%;
        border: solid 2px lightblue;
        background-color: rgb(53, 103, 119);
        overflow:hidden;
    }
    .rating-profile,
    .rating-data{
        width: 100%;
        height: 100%;
    }

    .imgSection{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        height: 50px;
    }
    .nameSection{
        display: grid;
        grid-template-rows: 1fr 1fr;
        margin-top: 10px;
    }
    .nameSection p{
        font-size: 12px;
        font-weight: bold;
        margin: 0px;
        width: 100%;
        text-overflow: ellipsis;
        word-wrap: none;
        overflow: hidden;
    }
    .starSection{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        position: relative;
        left: 5px;
    }
    .starSection img{
        height: 10px;
        width: 10px;
        margin-left: 1px;
        margin-right: 1px;
    }
    .date{
        font-size: 10px;
        font-weight: bold;
        margin: 0px;
        margin-left: 10px;
    }
    .textSection p{
        font-size: 12px;
        font-weight: bold;
        padding: 5px;
        margin: 0px;
    }
}