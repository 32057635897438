#Magazine{
    .logo-block{
        width: 100%;
        height: 70px;
        background-color: lightblue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .logo-block img{
        width: 200px;
        height: 100%;
    }
    .backButton{
        width: 80px;
        height: 25px;
        border-radius: 4vmax;
        box-shadow: 0px 0px 2px 0px black;
        position: absolute;
        left: 10px;
        top: 100px;
        border: none;
        background-color: lightblue;
        cursor: pointer;
        z-index: 10;
    }
    .body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
    }
    h1{
        font-size: 90px;
        background: linear-gradient(to right, #000428, #004e92, #00c6ff);
        -webkit-background-clip: text;
        background-clip: text; 
        -webkit-text-fill-color: transparent; 
    }

    @media (max-width:600px){
        h1{
            font-size: 70px; 
        }
    }

    @media (max-width:450px){
        h1{
            font-size: 50px; 
        }
    }
    @media (max-width:450px){
        h1{
            font-size: 30px; 
        }
    }
}