#message-page{
    background-color: rgba(0,0,0,0.4);
    height: 100dvh;
    position: fixed;
    top: 0px;
    left: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 101;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .Message{
        width: 80dvw;
        background-color: white;
        height: 85vh;
        margin-top: 45px;
        position: relative;
        border-radius: 1vmax;
        box-shadow: 0px 0px 10px 0px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }
    
    .message-header{
        margin: 0px;
        padding: 0px;
        background-color: lightblue;
        height: 80px;
        width: 80dvw;
        position: absolute;
        top: 0px;
        z-index: 100;
        border-top-left-radius: 1vmax;
        border-top-right-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .message-header h3{
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
    }
    
    .message-header p{
        width: 100%;
        text-align: center;
        position: relative;
        margin-top: 5px;
    }
    
    .close-message{
        width: fit-content !important;
        position: absolute !important;
        right: 25px;
        top: 20px;
        text-align: left !important;
        font-size: 20px !important;
        font-weight: bold;
        cursor: pointer;
    }
    
    .video-call{
        height: 30px;
        width: 30px;
        position: absolute;
        top: 25px;
        right: 14%;
        cursor: pointer;
    }
    
    .voice-call{
        height: 30px;
        width: 30px;
        position: absolute;
        top: 25px;
        right: 8%;
        cursor: pointer;
    }
    
    .profile-frame{
        box-shadow: 0px 0px 8px 0px black;
        border: 3px solid lightblue;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        position: absolute;
        top: 16px;
        left: 10%;
        background-color: rgb(53, 103, 119);
    }
    
    .profile-frame img{
        object-fit: contain;
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }

    .messages-block{
        width: 100%;
        position: absolute;
        top: 80px;
        height: calc(85vh - 80px);
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;   
    }

    .messages-anchor{
        width: 100%;
        height: 1px;
        margin-bottom: 80px;
    }

    .Message::-webkit-scrollbar,
    .messages-block::-webkit-scrollbar{
        width: 0px;
    }
    .Message::-webkit-scrollbar-thumb,
    .messages-block::-webkit-scrollbar-thumb{
        width: 0px;
    }

    .chat-block{
        width: 80%;
        height: 60px;
        position: absolute;
        display: grid;
        justify-items: center;
        align-items: center;
        bottom: 10px;
        background-color: lightblue;
        border-radius: 2vmax;
        box-shadow: 0px 0px 4px 0px black;
        grid-template-columns: 65px 1fr 60px;
    }
    
    .share-image{
        height: 25px;
        width: 25px;
        position: relative;
    }
    .share-image img{
        height: 100%;
        width: 100%;
    }
    .share-image input{
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 0px;
        left: 0px;
    }
        
    .camera-frame{
        box-shadow: 0px 0px 8px 0px black;
        border: 3px solid lightblue;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    
    .camera-frame img{
        height: 80%;
        width: 80%;
        cursor: pointer;
    }
    .camera-frame input{
        border-radius: 50%;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        position: absolute;
    }
    .chat-box{
        background-color: transparent;
        width: 100%;
        height: 50px;
        border: none;
        outline: none;
        font-size: 15px;
    }
    .chat-box::-webkit-scrollbar{
        width: 10px;
        background-color: rgba(0, 0, 0,0.2);
    }
    .chat-box::-webkit-scrollbar-thumb{
        width: 10px;
        background-color: rgba(0, 0, 0,0.5);
        border-radius: 1vmax;
        cursor: pointer;
    }
    
    .send{
        background-color: rgba(51, 131, 158, 0.9);
        border-radius: 100%;
        width: 50px;
        height: 50px;
        right: -60px;
        position: absolute;
        bottom: 5px;
        box-shadow: 0px 0px 4px 0px black;
    }
    .send img{
        height: 60%;
        width: 60%;
        position: absolute;
        top: 20%;
        left: 20%;
    }
    
    .date-card{
        margin-top: 15px;
        width: 46%;
        height: 25px;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 5vmax;
        background-color: silver;
        margin-left: 27%;
    }

    .date-card p{
        font-size: 12px;
        font-weight: bold;
        width: 100%;
        position: relative;
        bottom: 5px;
        text-align: center;
    }

    .messageCard{
        max-width: 500px;
        min-width: 130px;
        border-radius: 0.5vmax;
        margin: 20px;
        box-shadow: 0px 2px 4px 0px black;
        display: inline-block;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 30px;
        word-break: break-all;
    }

    .picCard1{
        width: 200px;
        height: 200px;
        border-radius: 0.5vmax;
        margin: 20px;
        box-shadow: 0px 2px 4px 0px black;
        display: inline-block;
        position: relative;
    }

    .picCard2{
        max-width: 400px;
        border-radius: 0.5vmax;
        margin: 20px;
        box-shadow: 0px 2px 4px 0px black;
        display: inline-block;
        position: relative;
        word-break: break-all;
    }

    .picSection{
        min-width: 200px;
        max-width: 400px;
        height: 200px;
    }

    .textSection{
        display: grid;
        grid-template-columns: 1fr 30px;
    }

    .picCard1 img,
    .picCard2 img{
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 0.5vmax;
    }
    
    .sender{
        background-color: lightblue;
        align-self: flex-end;
    }
    
    .receiver{
        background-color: white;
        align-self: flex-start;
    }

    .pic1-sender{
        border: solid 3px lightblue;
        background-color: rgb(53, 103, 119);
        align-self: flex-end;
        color: white;
    }

    .pic1-receiver{
        border: solid 3px white;
        background-color: rgb(215, 231, 236);
        align-self: flex-start;
        color: black;
    }

    .pic2-sender{
        border: solid 3px lightblue;
        background-color: lightblue;
        align-self: flex-end;
    }

    .pic2-receiver{
        border: solid 3px white;
        background-color: white;
        align-self: flex-start;
    }

    .ps-sender{
        background-color: rgb(53, 103, 119);
    }

    .ps-receiver{
        background-color: rgb(215, 231, 236);
    }

    .time{
        position: absolute;
        bottom: 2px;
        right: 4px;
        margin: 0px;
        font-size: 12px;
        font-weight: bold;
    }
    .time-pic{
        position: absolute;
        bottom: 2px;
        right: 4px;
        margin: 0px;
        font-size: 12px;
        font-weight: bold;
    }
    .text-pic{
        font-size: 15px;
    }
    .text{
        width: auto;
        margin-left: 4px;
        margin-bottom: 25px;
        font-size: 15px;
    }

    .Image-Display{
        width: 200px;
        height: 200px;
        border-radius: 1vmax;
        border: solid 4px lightblue;
        box-shadow: 0px 0px 2px 0px black;
        position: absolute;
        bottom: 80px;
        /* left: 300px; */
        background-color: rgb(53, 103, 119);
    }
    .Image-Display img{
        width: 100%;
        height: 100%;
        border-radius: 1vmax;
        object-fit: contain;
    }

    .remove-image{
        color: rgb(53, 103, 119);
        font-size: 25px;
        position: absolute;
        top: -70px;
        right: -30px;
        cursor: pointer;
    }

    @media (max-width:950px){
        .Message{
            width: 100dvw;
            height: 100vh;
            margin: 0px;
            position: absolute;
            bottom: 0px;
            top: 0px;
            border-radius: 0px;
        }
        .message-header{
            width: 100%;
            border-radius: 0px;
        }
        .messages-block{
            height: calc(100vh - 80px);  
        }
    }
    @media (max-width: 650px){
        .texting{
            left: 25px;
        }
    }
    @media (max-width: 600px){    
        .send{
            width: 50px;
            height: 50px;
            right: -60px;
        }
        .chat-block{
            grid-template-columns: 50px 1fr 45px;
        }
    }
    @media (max-width: 450px){
        .profile-frame{
            box-shadow: 0px 0px 8px 0px black;
            border: 3px solid lightblue;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            position: absolute;
            top: 20px;
            left: 10%;
            background-color: rgb(53, 103, 119);
        }
        .message-header h3{
            margin-top: 25px;
            font-size: 12px;
        }
        
        .message-header p{
            font-size: 12px;
        }
        .close-message{
            font-size: 17px !important;
        }
        .texting{
            left: 10px;
        }
        .text{
            font-size: 12px;
        }
    }
    @media (max-width: 400px){
        .texting{
            left: 5px;
        }
        .send{
            width: 45px;
            height: 45px;
            right: -50px;
        }
    }
}
#message-page::-webkit-scrollbar{
    width: 0px;
}
#message-page::-webkit-scrollbar-thumb{
    width: 0px;
}