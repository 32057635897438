.HouseCleaning{
    border-radius: 1vmax;
    border: 3px solid lightblue;
    height: 200px;
    width: 90%;
    margin-top: 10px;
    display: grid;
    background-color: white;
    grid-template-rows: 55px;
    box-shadow: 0px 0px 2px 0px black;
}

.hc-disclaimer{
    margin-top:5px;
    margin-bottom: 0px;
    width: 84%;
    margin-left: 26px;
    text-align: center;
    cursor: pointer;
}

.hc-disclaimer2{
    margin-top:5px;
    margin-bottom: 0px;
    width: 84%;
    height: 20px;
    margin-left: 26px;
    color: red;
    cursor: pointer;
}

@media (max-width: 450px){
    .hc-disclaimer,
    .hc-disclaimer2{
        font-size: 12px;
    }
}