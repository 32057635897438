#RemoveCard{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;

    div{
        width: 500px;
        height: 250px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: lightblue;
        position: relative;
        margin-top: 30vh;
    }
    h1{
        width: 90%;
        margin: 5%;
        text-align: center;
        font-size: 25px !important;
    }
    .Delete{
        width: 150px;
        height: 40px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 10px;
        left: 15%;
        color: white;
        background-color: black;
        cursor: pointer;
    }
    .Cancel{
        width: 150px;
        height: 40px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 10px;
        right: 15%;
        color: white;
        background-color: black;
        cursor: pointer;
    }

    @media(max-width: 600px){
        div{
            width: 90%;
        }
        .Delete,
        .Cancel{
            width: 100px;
            height: 35px;
        }
    }
    @media (max-width: 450px){
        div{
            width: 100vw;
            height: 100vh;
            border-radius: 0vmax;
            box-shadow: 0px 0px 0px 0px ;
            margin-top: 0vh;
        }
        .Delete,
        .Cancel{
            width: 80px;
            height: 30px;
        }
    }
}