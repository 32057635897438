
#Thumbnail1{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid lightblue;
    border-radius: 1vmax;
    box-shadow: 0px 0px 4px 0px black;
    height: 150px;
    width: 150px;
    flex-shrink: 0;
    position: absolute;
    top: 25%;
    background-color: white;
    cursor: pointer;

    .ImgHolder {
        height: 55px;
        width: 55px;
        border-radius: 50%;
        box-shadow: 0px 0px 2px 0px black;
        margin: 0px;
        position: relative;
        top: 2px;
        background-color: rgb(53, 103, 119);
        border: solid 2px lightblue;
    }
    
    .ImgHolder img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 50%;
    }
    
    .name{
        margin: 0px;
        font-size: 12px;
        text-align: center;
        position: relative;
        top: 4px;
    }
    
    .surname{
        margin: 0px;
        font-size: 12px;
        text-align: center;
        position: relative;
        top: 4px;
    }
    
    .jobTitle{
        font-weight:bold ;
        font-size: 15px;
        position: relative;
        top: 12px;
        width: 100%;
        text-align: center;
        margin: 0px;
    }

    .booking-date{
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        position: relative;
        margin: 0px;
        top: 20px;
    }

    span{
        position: relative;
        top: -4px;
    }
}