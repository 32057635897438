#Rater{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;

    .rater-block{
        width: 400px;
        min-height: 370px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: lightblue;
        position: relative;
        margin-top: 10vh;
        margin-bottom: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .list{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 60px;
        background-color: transparent;
        display: grid;
        justify-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    img{
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

    h3{
        width: 90%;
        text-align: center;
    }
    .Delete{
        width: 110px;
        height: 30px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 10px;
        left: 15%;
        color: white;
        background-color: black;
        cursor: pointer;
    }
    .Cancel{
        width: 110px;
        height: 30px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 10px;
        right: 15%;
        color: white;
        background-color: black;
        cursor: pointer;
    }
    textarea{
        outline: none;
        border-radius: 0.5vmax;
        width: 85%;
        background-color: white;
        height: 100px;
        margin-top:10px;
        color: black
    }
    p{
        margin: 0px;
        width: 85%;
        font-size: 15px;
        font-weight: bold;
    }
    .selected{
        box-shadow: 0px 0px 8px 0px darkblue;
    }

    @media (max-width: 450px){
        .rater-block{
            width: 100vw;
            min-height: 100vh;
            border-radius: 0vmax;
            margin-top: 0vh;
            margin-bottom: 0vh;
        }
            
        img{
            height: 30px;
            width: 30px;
            cursor: pointer;
        }
    
        .Delete{
            width: 80px;
            height: 25px;
        }
        .Cancel{
            width: 80px;
            height: 25px;
        }
        p{
            font-size: 12px;
        }
        .selected{
            box-shadow: 0px 0px 8px 0px darkblue;
        }
    }

}

#Rater::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255,255,255,0.3);
}
#Rater::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255,255,255,0.7);
}

@media (max-width: 450px){
    #Rater::-webkit-scrollbar{
        width: 0px;
    }
    #Rater::-webkit-scrollbar-thumb{
        width: 0px;
    }
}