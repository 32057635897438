#CheckoutButton{
    position: fixed;
    bottom: 20px;
    right: 7%;
    width: 100px;
    height: 40px;
    border-radius: 4vmax;
    box-shadow: 0px 0px 7px 0px darkblue;
    display: grid;
    align-items: center;
    grid-template-columns: 35px 1fr;
    background-color: rgba(173, 216, 230, 0.9);
    cursor: pointer;
    z-index: 10;

    img{
        height: 20px;
        width: 20px;
        margin-left: 10px;
    }
    p{
       font-size: 12px;
       font-weight: bold; 
    }
}
@media (max-width: 850px){
    #CheckoutButton{
        width: 90px;
        height: 40px;
        grid-template-columns: 35px 1fr;
        
        img{
            height: 15px;
            width: 15px;
            margin-left: 10px;
        }
        p{
            font-size: 9px;
        }
    }
}

