#Search{
    margin-top: 80px;
    width: 100dvw;
    min-height: 533px;
    position: relative;
    background-color: rgba(173, 216, 230, 0.2);
    display: grid;
    grid-template-columns: 290px 1fr;
    transition: 2s ease;
    overflow-x: hidden;

    .left-block{
        overflow: hidden;
        transition: 2s ease;
    }
    .right-block{
        transition: 2s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .filtersButton{
        width: 80px;
        height: 25px;
        border-radius: 4vmax;
        box-shadow: 0px 0px 2px 0px black;
        position: absolute;
        left: 10px;
        top: 20px;
        border: none;
        background-color: lightblue;
        cursor: pointer;
        z-index: 10;
    }

    .search-tab{
        height: 60px;
        width: 80%;
        margin-top: 50px;
        border-radius: 100vmax;
        background-color: white;
        box-shadow: 0px 0px 3px 0px black;
        display: grid;
        grid-template-columns: 1fr 70px;
        overflow: hidden;
    }
    
    .search-input{
        height: 87%;
        width: 97%;
        border: 0px;
        padding: 0px;
        outline: 0px;
        font-size: 15px;
        font-weight: bold;
        position: relative;
        left: 3%;
        top: 5%;
    }
    
    .search-button{
        height: 40px;
        width: 60px;
        box-shadow: 0px 0px 3px 0px black;
        border-radius: 50vmax;
        margin-top: 10px;
        margin-left: 5px;
        background-color: lightblue;
    }
    
    .search-button img{
        height: 30px;
        width: 30px;
        margin-top: 5px;
        margin-left: 15px;
    }
    
    .vertical-slideshow{
        width: 290px;
        height: 533px;
        position: sticky;
        left: 0px;
        top: 74px;
        display: grid;
        grid-template-rows: 20% 1fr 20%;
    }
    
    .slide-top,
    .slide-bottom{
        position: relative;
    }
    
    .slide-middle{
        overflow: hidden;
        position: relative;
    }
    
    .slide-top div,
    .slide-bottom div{
        height: 35px;
        width: 35px;
        background-color: rgba(0,0,0,0.8);
        border-radius: 50%;
        position: absolute;
        left: 40%;
    }
    
    .slide-top div{
        top: 50%;
        cursor: pointer;
    }
    .slide-bottom div{
        top: 26%;
        cursor: pointer;
    }
    
    .slide-top img,
    .slide-bottom img{
        height: 22px;
        width: 18px;
        position: relative;
        top: 6px;
        left: 8px;
    }
    
    .search-results{
        min-height: 395px;
        width: 90%;
        margin-top: 100px;
        position: relative;
    }
    
    .search-results h1{
        font-size: 50px;
        color: black;
        margin-top: 0px;
    }

    @media (max-width:550px){
        .search-results h1{
            font-size: 40px;
        }
        .search-input{
            font-size: 12px;
        }
    }
    @media (max-width:400px){
        .search-results h1{
            font-size: 25px;
        }
    }
}

