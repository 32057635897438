#VideoCard{
    height: 500px;
    margin: 15px;
    display: grid;
    grid-template-rows: 350px 1fr;
    border-radius: 1vmax;
    position: relative;
    cursor: pointer;

    video{
        height: 350px;
        width: 100%;
        border-radius: 1vmax;
        box-shadow: 0px 0px 5px 0px black;
        object-fit: cover;
        object-position: center;
    }

    .data-block{
        height: 100%;
        width: 100%;
    }

    .vc-profile{
        display: grid;
        grid-template-columns: 40px 1fr;
    }

    .vc-image{
        border-radius: 100%;
        height: 30px;
        width: 30px;
        margin-left: 5px;
        border: solid 3px lightblue;
        box-shadow: 0px 0px 2px 0px black;
        background-color: rgb(53, 103, 119);
    }
    .vc-data{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .vc-image img{
        height: 100%;
        width: 100%;
        border-radius: 100%;
        object-fit: contain;
    }

    .vc-name{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        margin: 0px;
        margin-top: 12px;
        margin-left: 10px;
        font-size: 12px;
    }

    .vc-service{
        width: 100%;
        text-align: center;
        margin: 0px;
        margin-top:5px;
    }
    .vc-service span{
        position: relative;
        top: -5px;
    }

    .vc-jobsData{
        margin-top: 5px;
        display: grid;
        grid-template-columns: 60px 1fr;
    }
    .vc-rating{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .vc-rating p{
        top: 410px;
        left: 44px;
        width: fit-content;
        margin-top: 7px;
        margin-left: 10px;
        margin-right: 5px;
        font-size: 12px;
    }

    .vc-rating img{
        height: 25px;
        width: 25px;
    }

    .vc-jobs{
        width: 100px;
        margin-top: 7px;
        margin-left: 10px;
        margin-right: 5px;
        font-size: 12px;
    }

    .vc-location{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .vc-location p{
        width: 145px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0px;
        font-size: 12px;
    }
    .vc-location img{
        height: 20px;
        width: 20px;
        margin-left: 7px;
    }

    @media (max-width:1250px){
        width: 215px;
    }
}
