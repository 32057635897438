#AddFaq{ 
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;

    .table{
        width: 400px;
        height: 450px;
        background-color: lightblue;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0.5vmax; 
        overflow: hidden;
    }
    .table button{
        height: 30px;
        width: 100px;
        border-radius: 50vmax;
        margin-top: 5%;
        cursor: pointer;
    }
    .qBlock{
        width: 80%;
        height: 130px;
        position: relative;
    }
    .aBlock{
        width: 80%;
        height: 180px;
        position: relative;
    }
    .qBlock p,
    .aBlock p{
        font-size: 15px;
        font-weight: bold;
        width: 100%;
    }
    .questionInput{
        width: 100%;
        height: 80px;
        border-radius: 0.2vmax;
    }
    .answerInput{
        width: 100%;
        height: 120px;
        border-radius: 0.2vmax;
    }
    .close-tab{
        position: absolute;
        top: 0px;
        right: 10px;
        margin: 5px;
        font-size: 20px;
        cursor: pointer;
    }
    @media (max-width: 450px){
        .table{
            width: 100vw;
            min-height: 100vh;
        }
        h1{
            font-size: 20px
        }
        button{
            width: 100px;
            margin-top: 10%;
        }
        .close-tab{
            font-size: 15px;
            font-weight: bold;
        }
    }
}

#AddFaq::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255,255,255, 0.3);
}
#AddFaq::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255,255,255, 0.7);
    border-radius: 4vmax;
}
@media (max-width:450px){
    #AddFaq::-webkit-scrollbar{
        width: 0px;
    }
    #AddFaq::-webkit-scrollbar-thumb{
        width: 0px;
    } 
}