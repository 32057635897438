body{
    margin: 0px;
}

#signInPage{
    display: grid;
    grid-template-rows: 67px 3px 1fr;

    .logo-block{
        width: 100%;
        height: 100%;
        background-color: lightblue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .logo-block img{
        width: 200px;
        height: 100%;
    }
    
    .signInUnderliner{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .siLine1{
        background-color: lightblue;
    }
    
    .siLine2{
        background-color: lightblue;
    }
    
    form{
        width: 100%;
        height: 533px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
    }
    h2{
        text-align: center;
        position: relative;
        margin-top: 50px;
        width: 90%;
    }

    .password,
    .email{
        margin-top: 30px;
        width: 500px;
        height: 80px;
        position: relative;
    }
    
    .password input,
    .email input{
        width: 100%;
        height: 60px;
        position: relative;
        border-radius: 100vmax;
        background-color: transparent;
        font-size: 15px;
    }

    .password a{
        position: absolute;
        right: 0px;
        color: black;
        margin-top: 3px;
    }
    
    .passwordPlaceholderSI,
    .emailPlaceholderSI{
        position: absolute;
        top: 20px;
        left: 10%;
        font-size: 20px; 
        transition: 1s ease;  
    }
    
    .passwordPlaceholderSI.active,
    .emailPlaceholderSI.active{
        top: -20px;
        font-size: 17px;
        background-color: white; 
    }
    
    .link{
        width: 500px;
        position: relative;
        margin-top: 10px;
    }
    .link div{
        display: flex;
        flex-direction: row;
        width: fit-content;
        position: absolute;
        right: 0px;
    }
    .link p{
        margin: 0px;
        margin-right: 10px;
    }
    .link a{
        color: black;
    }
    .sign-in{
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-top: 20px;
    }
    .sign-in button{
        height: 40px;
        width: 200px;
        background-color: black;
        border-radius: 100vmax;
        color: white;
    }
    
    .required{
        position: absolute;
        top: -40px;
        right: 0px;
        color: red;
    }

    @media(max-width: 550px){
        h2{
            font-size: 18px;
        }

        .link,
        .sign-in,
        .password,
        .email{
            width: 90%;
        }
        .sign-in button{
            height: 35px;
            width: 160px;
            background-color: black;
            border-radius: 100vmax;
            color: white;
        }
        a,
        .link p,
        .passwordPlaceholderSI,
        .emailPlaceholderSI{
            font-size: 15px;
        }
        .password input,
        .email input{
            font-size: 12px;
        }
    }
    @media(max-width: 450px){
        .sign-in{
            align-items: center;
        }
    }
}

