#PrivacyPolicy{
    width: 100dvw;
    min-height: 100vh;
    background-color: rgba(173, 216, 230, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-block{
        width: 100%;
        height: 70px;
        background-color: lightblue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .logo-block img{
        width: 200px;
        height: 100%;
    }
    .backButton{
        width: 80px;
        height: 25px;
        border-radius: 4vmax;
        box-shadow: 0px 0px 2px 0px black;
        position: absolute;
        left: 10px;
        top: 100px;
        border: none;
        background-color: lightblue;
        cursor: pointer;
        z-index: 10;
    }

    .document{
        width: 600px;
        min-height: 1000px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
        margin-top: 100px;
        margin-bottom: 100px;
        padding: 80px;
    }
    h2{
        text-align: center;
    }
    .policy-section{
        width: 100%;
        min-height: 120px;
        border-bottom: solid 2px gray;
    }
    .policy-title{
        font-size: 17px;
        font-weight: bold;
    }
    .policy-text{
        font-size: 17px;
    }
    ul {
        list-style-type: disc;
    }
    .numerical-points{
        list-style-type:decimal;
    }
    li {
        font-size: 17px;
        margin-bottom: 10px;
    }
    .bold{
        font-weight: bold;
    }
    @media (max-width:1000px){
        .document{
            width: 60%;
            padding: 40px;
        }
    }
    @media (max-width:500px){
        .document{
            width: 90%;
            height: 100%;
            padding: 5%;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        h2{
            margin-top: 70px;
        }
    }
    @media (max-width:400px){

        h2{
            font-size: 20px;
        }
        
        .policy-text,
        li,
        p{
            font-size: 12px;
            font-weight: bold;
        }
        .policy-title{
            font-size: 15px;
            font-weight: bold;
        }
        ul{
            padding-inline-start: 10%;
        }
    }
}