.ExecCard{
    background-color: white;
    transition: 2s;
    display: grid;
    grid-template-rows: 40% 10% 10% 40%;
    position: absolute;
}
.imgFrame{
    height: 100%;
    width: 100%;
    border: solid 4px lightblue;
    border-radius: 1vmax;
    background-color: rgb(53, 103, 119);
    object-fit: contain;
    position: relative;
    left: -2%;
    transition: 2s;
}
.imgFrame img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.ExecCard p,
.ExecCard h3,
.ExecCard h4{
    margin: 0px;
    margin-top: 10px;
    transition: 2s;
}
