#Report{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;

    .report-block{
        width: 400px;
        min-height: 400px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: lightblue;
        position: relative;
        margin-top: 10vh;
        margin-bottom: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .list{
        width: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 110px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .list::-webkit-scrollbar{
        width: 8px;
        background-color: rgba(0,0,0,0.3);
    }
    .list::-webkit-scrollbar-thumb{
        border-radius: 4vmax;
        width: 8px;
        background-color: rgba(0,0,0,0.7);
    }
    .report-type{
        height: 40px;
        width: 90%;
        margin-left: 5%;
        margin-top: 7px;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 0.5vmax;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: lightblue;
        cursor: pointer;
    }
    .report-type p{
        text-align: center;
        width: 100%;
        margin: 0px;
        font-size: 14px;
        font-weight: bold;
    }

    h3{
        width: 90%;
        margin-top: 5%;
        margin-bottom: 5%;
        text-align: center;
    }
    .Delete{
        width: 110px;
        height: 30px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 10px;
        left: 15%;
        color: white;
        background-color: black;
        cursor: pointer;
    }
    .Cancel{
        width: 110px;
        height: 30px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 10px;
        right: 15%;
        color: white;
        background-color: black;
        cursor: pointer;
    }
    textarea{
        outline: none;
        border-radius: 0.5vmax;
        width: 90%;
        background-color: white;
        height: 100px;
        margin-top:10px;
        color: black
    }
    p{
        margin: 0px;
        width: 90%;
        font-size: 15px;
        font-weight: bold;
    }
    .selected{
        box-shadow: 0px 0px 8px 0px darkblue;
    }

    @media (max-width:450px){
        .report-block{
            width: 100vw;
            min-height: 100vh;
            border-radius: 0vmax;
            margin-top: 0vh;
            margin-bottom: 0vh;
        }
        
        .report-type p{
            font-size: 12px;
        }

        p{
            font-size: 12px;
        }
        .Delete{
            width: 80px;
            height: 25px;
            bottom: 20px;
        }
        .Cancel{
            width: 80px;
            height: 25px;
            bottom: 20px;
        }
    }
}

#Report::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255,255,255,0.3);
}
#Report::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255,255,255,0.7);
}

@media (max-width: 450px){
    #Report::-webkit-scrollbar{
        width: 0px;
    }
    #Report::-webkit-scrollbar-thumb{
        width: 0px;
    } 
}
