.SlideShow{
    height: 210px;
    width: 100%;
    margin: 0px;
    display: grid;
    grid-template-columns: 5% 1fr 5%;
    position: relative;
}

.slide-blocka,
.slide-blockc{
    position: relative;
}

.slide-blockb{
    overflow: hidden;
    position: relative;
}

.slide-blocka div,
.slide-blockc div{
    height: 35px;
    width: 35px;
    background-color: rgba(0,0,0,0.8);
    border-radius: 50%;
    position: absolute;
    top: 40%;
}

.slide-blocka div{
    right: 0%;
    cursor: pointer;
}
.slide-blockc div{
    left: 0%;
    cursor: pointer;
}

.slide-blocka img,
.slide-blockc img{
    height: 25px;
    width: 25px;
    position: relative;
    top: 5px;
    left: 5px;
}

.service-name{
    position: absolute;
    top: 0px;
    margin: 0px;
    font-size: 30px;
    font-weight: bold;
    color: black;
}

.underline{
    width: 85%;
    height: 3px;
    background-color: lightblue;
    position: absolute;
    top: 34px;
    box-shadow: 0px 0px 3px 0px black;
    border-radius: 100vmax;
}

@media (max-width: 900px){
    .SlideShow{
        height: 210px;
        width: 100%;
        margin: 0px;
        display: grid;
        grid-template-columns: 0% 1fr 0%;
        position: relative;
    }
    .slide-blocka div{
        right: -20px;
    }
    .slide-blockc div{
        left: -20px;
    }
}

@media (max-width: 550px){
    .service-name{
        font-size: 20px;
    }
}