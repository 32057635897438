#ImageDisplay{
    position: absolute;
    .faint{
        height: 100dvh;
        width: 100dvw;
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .faint::-webkit-scrollbar{
        width: 10px;
        background-color: rgba(255,255,255,0.3);
    }
    .faint::-webkit-scrollbar-thumb{
        width: 10px;
        background-color: rgba(255,255,255,0.7);
    }
    .image-block{
        width: 400px;
        min-height: 500px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        position: relative;
        margin-top: 10%;
        margin-bottom: 10%;
        background-color: rgba(24, 75, 92,0.5);
        overflow: hidden;
    }
    .image{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    .description-block{
        height: 130px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        background-color: rgb(173, 216, 230,0.2);
        border-bottom-left-radius: 1vmax;
        border-bottom-right-radius: 1vmax;
    }
    .description-block p{
        width: 350px;
        color: white;
        margin-left: 5%; 
        word-break: break-word;
    }
    .close-image{
        font-size: 25px;
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0px;
        color: lightblue;
        cursor: pointer;
    }
    .createdDate{
        background-color: rgba(67, 115, 131, 0.7);
        color: white;
        width: 70px;
        height: 20px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        border-radius: 0.2vmax;
        position: absolute;
        bottom: 0px;
        right: 10px;
        box-shadow: 0px 0px 2px 0px white;
    }

    @media (max-width: 450px){
        .image-block{
            width: 100%;
            min-height: 100%;
            border-radius: 0vmax;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .description-block p{
            width: 90%;
        }
        .faint::-webkit-scrollbar{
            width: 0px;
        }
        .faint::-webkit-scrollbar-thumb{
            width: 0px;
        }
    }
}