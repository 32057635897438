#Chats{
    background-color: rgba(0,0,0,0.4);
    height: 100vh;
    position: fixed;
    top: 0px;
    overflow: hidden;
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .Chats-list{
        width: 400px;
        height: 80vh;
        position: relative;
        margin-top: 10vh;
        background-color: lightblue;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 0.5vmax;
        justify-items: center;
        display: grid;
        grid-template-rows: 90px 1fr;
    }

    .search-tab{
        width: 95%;
        margin-top: 40px;
        height: 40px;
        outline: none;
        border: 0px;
        border-radius: 0.5vmax;
    }

    .close-chats{
        position: absolute;
        top: -15px;
        right: 20px;
        font-size: 20px;
        font-weight: bold;
        color: black;
        cursor: pointer;
    }

    .list{
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100%;
        width: 100%;
    }
    .list::-webkit-scrollbar{
        width: 6px;
        background-color: rgba(0,0,0,0.4);
    }
    .list::-webkit-scrollbar-thumb{
        border-radius: 4vmax;
        background-color: rgba(0,0,0,0.8);
    }

    .chatCard{
        width: 95%;
        height: 70px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        margin-left: 2.5%;
        margin-top: 10px;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 70px 1fr;
        cursor: pointer;
    }
    .profile-frame{
        height: 50px;
        width: 50px;
        border-radius: 100%;
        border: 3px solid lightblue;
        box-shadow: 0px 0px 2px 0px black;
        margin: 8px;
        background-color: rgb(53, 103, 119);
    }
    .profile-frame img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 100%;
    }
    .data{
        display: grid;
        grid-template-rows: 50% 50%;
    }
    .messengerData{
        display: grid;
        grid-template-columns: 1fr 70px;
    }
    .name{
        font-size: 15px;
        font-weight: bold;
        width: fit-content;
        margin-left: 15px;
    }
    .time{
        font-size: 12px;
        font-weight: bold;
        width: 100%;
        margin-right: 5px;
    }
    .snippet{
        display: grid;
        grid-template-columns: 1fr 50px;
    }
    .text{
        white-space: nowrap;        
        overflow: hidden;           
        text-overflow: ellipsis;    
        width: 100%; 
        margin: 0px;
        margin-left: 15px;
        height: fit-content;
        position: relative;
        top: 10px;
    }

    .unseen1,
    .unseen2{
        height: 20px;
        background-color: rgba(72, 152, 179, 0.5);
        border-radius: 4vmax;
        font-size: 11px;
        font-weight: bold;
        margin: 0px;
        text-align: center;
        position: relative;
        top: 10px;
    }

    .unseen1{
        width: 20px;
    }
    .unseen2{
        width: 35px;
    }

    .unseen1 p,
    .unseen2 p{
        position: relative;
        top: -7px;
    }

    @media (max-width:600px){
        .Chats-list{
            width: 100vw;
            height: 100vh;
            margin-top: 0vh;
            border-radius: 0vmax;
        }
        time{
            font-size: 10px;
        }
        .name{
            font-size: 12px;
        }
    }
}

