#Thumbnail{
    display: grid;
    grid-template-rows: 2.5fr 1fr 1fr;
    border: 3px solid lightblue;
    border-radius: 1vmax;
    box-shadow: 0px 0px 4px 0px black;
    height: 130px;
    width: 130px;
    flex-shrink: 0;
    position: absolute;
    top: 25%;
    background-color: white;
    cursor: pointer;

    .ImgHolder {
        height: 55px;
        width: 55px;
        border-radius: 50%;
        box-shadow: 0px 0px 2px 0px black;
        margin: 0px;
        position: relative;
        top: 2px;
        left: 35px;
        background-color: rgb(53, 103, 119);
        border: solid 2px lightblue;
    }
    
    .ImgHolder img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 50%;
    }
    
    .name{
        margin: 0px;
        font-size: 12px;
        text-align: center;
        position: relative;
        top: -4px;
    }
    
    .surname{
        margin: 0px;
        font-size: 12px;
        text-align: center;
        position: relative;
        top: -14px;
    }
    
    .jobTitle{
        position: absolute;
        font-weight:bold ;
        font-size: 15px;
        bottom: 0px;
        width: 100%;
        text-align: center;
        margin: 0px;
        margin-bottom: 5px;
    }

    span{
        position: relative;
        top: -4px;
    }
}



