#UpdateImage{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;

    .updateContent{
        overflow: hidden;
        position: relative;
        width: 400px;
        min-height: 400px;
        background-color: rgba(71, 149, 175, 0.9); 
        z-index: 110;
        border-radius: 1vmax;   
        transition: 1.5s;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .updateContent h1{
        margin-top: 30px;
        text-align: center;
    }
    .close-tab{
        position: absolute;
        top: 0px;
        right: 10px;
        margin: 5px;
        font-size: 20px;
        cursor: pointer;
    }
    .form{
        width: 330px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .form p{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;      
    }
    .form textarea{
        width: 100%;
        height: 130px;
    }
    .form input{
        width: 100%;
    }
    .form button{
        height: 30px;
        width: 100px;
        border-radius: 50vmax;
        position: relative;
        top: 20px;
    }

    @media (max-width: 450px){
        .updateContent{
            width: 100vw;
            min-height: 100vh;
        }
        .form{
            width: 90vw;
        }
        .updateContent h1{
            font-size: 20px
        }
        .form button{
            width: 100px;
            margin-top: 10%;
        }
    }
}

#UpdateImage::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255,255,255,0.3);
}
#UpdateImage::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255,255,255,0.7);
}

@media (max-width:450px){
    #UpdateImage::-webkit-scrollbar{
        width: 0px;
    }
    #UpdateImage::-webkit-scrollbar-thumb{
        width: 0px;
    }
}