#schedule-page{
    background-color: rgba(0,0,0,0.4);
    height: 100vh;
    position: fixed;
    top: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1000;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .close-schedule{
        position: absolute;
        right: 5%;
        font-size: 25px;
        top: 20px;
        font-weight: bold;
        cursor: pointer;
        z-index: 1001;
        margin: 0px;
    }
    
    .schedule-title{
        height: 100px;
        text-align: center;
        font-size: 45px;
        margin: 0px;
        position: relative;
        top: 10px;
    }
    
    .submit-block{
        height: 80px;
        width: 50dvw;
        background-color: transparent;
        z-index: 10;
    }
    
    .submit-block h3{
        text-align: center;
    }
    
    .submit-block div{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        height: 60px;
        margin-top:8px;
        margin-bottom: 8px;
        margin-left: 3%;
        border: 3px solid white;
        width: 93%;
        border-radius: 100vmax;
        background-color: rgb(173, 216, 230);
        box-shadow: 0px 0px 2px 0px black;
        position: relative;
    }
    
    .schedule-body{
        background-color:  rgb(173, 216, 230,0.7);
        width: 50dvw;
        height: fit-content;
        position: relative;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 10px 0px black;
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .main-disclaimer{
        height: 80px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .main-disclaimer h3{
        text-align: center;
        width: 100%;
    }
    
    .schedule-calender{
        display: grid;
        grid-template-rows: 50px 350px 1fr;
        min-height: 450px;
        width: 90%;
        border-radius: 3vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
    }

    .fixed-calendar{
        margin-bottom: 50px;
    }
    
    .Month-selector{
        width: 100%;
        background-color: lightblue;
        border-top-left-radius: 2vmax;
        border-top-right-radius: 2vmax;
        position: relative;
    }
    
    .Month-selector p{
        position: relative;
        left: 20px;
        font-weight: bold;
        width: fit-content;
    }
    
    .Day-selector{
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
     
    .month-above{
        position: absolute;
        top: 15px;
        height: 15px;
        width: 15px;
        right: 15px;
    }
    
    .month-below{
        position: absolute;
        top: 15px;
        height: 15px;
        width: 15px;
        right: 45px;
    }
        
    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    .grid p{
        text-align: center;
        cursor: pointer;
    }
        
    .Appointment-date{
        position: absolute;
        right: 100px;
    }

    .slots-block{
        width: 100%;
        background-color: lightblue;
        border-bottom-left-radius: 2vmax;
        border-bottom-right-radius: 2vmax;
        position: relative;
        display: grid;
    }
    .car-slots{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .house-slots{
        grid-template-columns: 1fr 1fr;
    }
    .garden-slots{
        grid-template-columns: 1fr 1fr;
    }
    .pool-slots{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .unfixed-slots{
        grid-template-columns: 1fr 1fr;
    }
    
    .slot{
        height: 70px;
        width: 80%;
        margin: 10%;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        position: relative;
        cursor: pointer;
    }
    
    .slot-name{
        font-size: 11px;
        font-weight: bold;
        position: absolute;
        left: 5px;
        top: 0px;
    }
    .slot-duration{
        font-size: 11px;
        font-weight: bold;
        position: absolute;
        left: 5px;
        bottom: 0px;
    }

    .resting-tag,
    .unavailable-tag,
    .available-tag{
        font-size: 10px;
        font-weight: bold;
        position: absolute;
        bottom: 0px;
        right: 5px;
    }

    .unavailable-tag{
        color: red;
    }

    .available-tag{
        color: green;
    }

    .resting-tag{
        color: darkblue;
    }

    .slot-filter{
        border-radius: 0.5vmax;
        background-color: rgba(0,0,0,0.4);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        display: none;
    }

    .Message0{
        width: 120px;
        height: 120px;
        box-shadow: 0px 0px 50px 0px lightblue;
        background-color: lightblue;
        padding: 5px;
        position: fixed;
        top: 45%;
        right: 14%;
        z-index: 1001;
    }

    .message0{
        text-align: center;
    }
    
    @media (max-width: 1000px){
        .car-slots,
        .house-slots,
        .garden-slots,
        .pool-slots,
        .unfixed-slots{
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (max-width: 900px){

        .schedule-body{
            width: 70dvw;
        }

    }
    @media (max-width: 700px){

        .schedule-body{
            width: 90dvw;
        }
    }
    @media (max-width: 450px){

        .schedule-body{
            width: 100dvw;
            margin: 0px;
            border-radius: 0px;
        }

        .schedule-title{
            font-size: 30px;
            height: 60px;
            margin-top: 20px;
        }
        .car-slots,
        .house-slots,
        .garden-slots,
        .pool-slots,
        .unfixed-slots{
            grid-template-columns: 1fr;
        }

        .main-disclaimer h3{
            font-size: 15px;
        }
        .submit-block h3{
            font-size: 12px;
        }
        .close-schedule{
            font-size: 20px;
        }
    }
}
#schedule-page::-webkit-scrollbar{
    width: 0px;
}