#ImageCard{
    height: 300px;
    margin: 5px;
    box-shadow: 0px 0px 2px 0px black;
    border-radius: 1vmax;
    position: relative;
    overflow: hidden;

    .createdDate{
        background-color: rgba(67, 115, 131, 0.7);
        color: white;
        width: 70px;
        height: 20px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        border-radius: 0.2vmax;
        position: absolute;
        bottom: 0px;
        right: 10px;
    }
    .image{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    .options{
        height: 20px;
        width: 20px;
        position: absolute;
        right: 4px;
        bottom: 40px;
        cursor: pointer;
    }
    .click{
        height: 50px;
        width: 50px;
        position: absolute;
        left:45%;
        top: 42%;
        cursor: pointer;
        opacity: 0;
    }
    .click:hover{
        opacity: 1;
    }
    .options-block{
        border-radius: 0.5vmax;
        width: 130px;
        height: 150px;
        box-shadow: 0px 0px 2px 0px black;
        position: absolute;
        bottom: 10px;
        right: 20px;
        background-color: rgba(71, 149, 175, 0.9);
        z-index: 100;
    }
    .option-button{
        display: grid;
        grid-template-columns: 2fr 1fr;
        margin-top: 15px;
    }
    .option-button p:hover{
        color: white;
    }
    .option-button p{
        margin-left: 8px;
        cursor: pointer;
    }
    
    .option-button img{
        height: 20px;
        width: 20px;
        position: relative;
        top: 15px;
        left: 5px;
    }
}
