
#booking-page{
    background-color: rgba(0,0,0,0.4);
    height: 100vh;
    position: fixed;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1000;
    width: 100%;

    .close-booking{
        position: absolute;
        right: 5%;
        font-size: 25px;
        top: 20px;
        font-weight: bold;
        cursor: pointer;
        z-index: 1001;
        margin: 0px;
    }
    
    .booking-specs{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .booking-title{
        height: 100px;
        text-align: center;
        font-size: 45px;
        margin: 0px;
        position: relative;
        top: 10px;
    }
    /* ||||||||| Car Cleaner |||||||| */
    .heading-block{
        height: 60px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
        position: fixed;
        top: 80px;
        width: 100%;
        z-index: 10;
    }
    
    .heading-block h2{
        text-align: center;
        margin-top: 17px;
        margin-bottom: 17px;
    }
    
    .submit-block{
        height: 80px;
        width: 50dvw;
        background-color: transparent;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
    
    .submit-block h3{
        text-align: center;
        margin:0px;
    }
    
    .submit-block div{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        height: 60px;
        margin-top:8px;
        margin-bottom: 8px;
        border: 3px solid white;
        width: 93%;
        border-radius: 100vmax;
        background-color: rgb(173, 216, 230);
        box-shadow: 0px 0px 2px 0px black;
        position: relative;
        align-items: center;
        cursor: pointer;
    }
    
    .booking-body{
        background-color:  rgb(173, 216, 230,0.7);
        width: 50dvw;
        height: fit-content;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 10px 0px black;
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    
    .car-count{
        height: 80px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        display: grid;
        grid-template-columns: 3.5fr 2.5fr;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .car-count h3{
        text-align: center;
        margin-top: 29px;
        margin-bottom: 29px;
    }
    
    .car-count div{
        width: 100px;
        height: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        top: 15px;
        border-radius: 0.5vmax;
    }
    
    .car-count button{
        height: 50px;
        background-color: rgb(241, 250, 253);
        font-size: 22px;
        color: lightblue;
    }
    
    .cc-btn1{
        border-bottom: 3px solid lightblue;
        border-top: 3px solid lightblue;
        border-left: 3px solid lightblue;
        border-right: 1.5px solid lightblue;
        border-top-left-radius: 1vmax;
        border-bottom-left-radius: 1vmax;
    }
    
    .cc-btn2{
        border-bottom: 3px solid lightblue;
        border-top: 3px solid lightblue;
        border-right: 3px solid lightblue;
        border-left: 2px solid lightblue;
        border-top-right-radius: 1vmax;
        border-bottom-right-radius: 1vmax;
    }
    
    /* ||||||||| House Cleaner |||||||| */
    
    .booking-body2{
        background-color:  rgb(173, 216, 230,0.7);
        width: 50dvw;
        height: fit-content;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 10px 0px black;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    
    .room-count{
        height: 200px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        display: grid;
        grid-template-rows: 20px 1fr 20px 1fr;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .rc-disclaimer{
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 20px;
        color: red;
    }
    
    .rc-disclaimer2{
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 20px;
        color: red;
        cursor: pointer;
    }
    
    .rooms{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 150px;
        position: relative;
    }
    
    .room-count h3{
        margin-left: 20px;
    }
    
    .rooms div{
        width: 100px;
        height: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        top: 10px;
        border-radius: 0.5vmax;
    }
    
    .room-count button{
        height: 50px;
        background-color: rgb(241, 250, 253);
        font-size: 22px;
        color: lightblue;
        cursor: pointer;
    }
    
    .hc-commonTasks{
        height: 600px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        display: grid;
        grid-template-rows: 85px 85px 85px 85px 85px;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .Spec{
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        position: relative;
    }
    
    .Spec h4{
        margin: 0px;
        margin-top: 5px;
        position: relative;
        left: 30px;
        width: 80%;
    }
    
    .option{
        display: grid;
        grid-template-columns: 70px 280px 70px;
        width: 100%;
    }
    
    .option div{
        height: 13px;
        width: 13px;
        border-radius: 50%;
        border: 2px solid black;
        position: relative;
        top: 8px;
        left: 30px;
        cursor: pointer;
        box-shadow: 0px 0px 3px 0px black;
    }
    
    .option p{
        margin: 0px;
        margin-top: 8px;
    }
    
    .disclaimer{
        width: 90%;
        text-align: center;
        position: relative;
        left: 18px;
        color: black;
        cursor: pointer;
    }
    
    .disclaimer2{
        width: 90%;
        position: relative;
        left: 28px;
        color: red;
        cursor: pointer;
    }
    
    .maxTime{
        position: absolute;
        width: 130px;
        top: 6px;
        left: 190px;
        margin: 0px;
        font-size: 13px;
        color: red;
    }
    
    .time{
        font-size: 15px;
        font-weight: bold;
        margin-top: 22px;
    }
    
    /* ||||||||| Pool Cleaning ||||||||| */
    .booking-body3{
        background-color:  rgb(173, 216, 230,0.7);
        width: 50dvw;
        height: fit-content;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 10px 0px black;
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    
    .main-disclaimer{
        height: 200px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .pool-specs{
        display: grid;
        grid-template-columns: 1fr;
        height: 440px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .main-disclaimer h3{
        text-align: center;
        width: 100%;
    }
    
    /* |||||| Gardener |||||| */
    
    .booking-body4{
        background-color:rgb(173, 216, 230,0.7);
        width: 50dvw;
        height: fit-content;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 10px 0px black;
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    
    .main-disclaimer4{
        height: 150px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .garden-specs{
        display: grid;
        grid-template-columns: 1fr;
        height: 440px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
    }
    
    .main-disclaimer4 h3{
        text-align: center;
        width: 85%;
    }
    
    .disclaimer-4{
        position: absolute;
        width: 130px;
        top: 6px;
        left: 250px;
        margin: 0px;
        font-size: 13px;
        color: red;
        cursor: pointer;
    }
    
    /* ||||||| Negotiable |||||||  */
    
    .booking-body5{
        background-color:rgb(173, 216, 230,0.7);
        width: 50dvw;
        height: fit-content;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 10px 0px black;
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    
    .main-disclaimer5{
        height: 80px;
        width: 90%;
        border-radius: 1vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .main-disclaimer5 h3{
        text-align: center;
        width: 85%;
    }
    
    .booking-calender{
        display: grid;
        grid-template-rows: 50px 350px 1fr;
        min-height: 450px;
        width: 90%;
        border-radius: 3vmax;
        border: 3px solid lightblue;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
    }

    .fixed-calendar{
        margin-bottom: 50px;
    }
    
    .Month-selector{
        width: 100%;
        background-color: lightblue;
        border-top-left-radius: 2vmax;
        border-top-right-radius: 2vmax;
        position: relative;
    }
    
    .Month-selector p{
        position: relative;
        left: 20px;
        font-weight: bold;
        width: fit-content;
    }
    
    .Day-selector{
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    
    .Hour-selector{
        width: 100%;
        background-color: lightblue;
        border-bottom-left-radius: 2vmax;
        border-bottom-right-radius: 2vmax;
        position: relative;
    }
    
    .Hour-selector p{
        position: relative;
        left: 20px;
        font-weight: bold;
        width: fit-content;
    }
    
    .month-above{
        position: absolute;
        top: 15px;
        height: 15px;
        width: 15px;
        right: 15px;
    }
    
    .month-below{
        position: absolute;
        top: 15px;
        height: 15px;
        width: 15px;
        right: 45px;
    }
    
    .hour-above{
        position: absolute;
        top: 15px;
        height: 15px;
        width: 15px;
        left: 125px;
    }
    
    .hour-below{
        position: absolute;
        top: 15px;
        height: 15px;
        width: 15px;
        left: 160px;
    }
    
    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    .grid p{
        text-align: center;
        cursor: pointer;
    }
    
    .TimeRange{
        position: absolute;
        top: 15px;
        right: 10px;
        width: fit-content;
        font-size: 11px;
        font-weight: bold;
    }
    
    .slots-block{
        width: 100%;
        background-color: lightblue;
        border-bottom-left-radius: 2vmax;
        border-bottom-right-radius: 2vmax;
        position: relative;
        display: grid;
    }
    .car-slots{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .house-slots{
        grid-template-columns: 1fr 1fr;
    }
    .garden-slots{
        grid-template-columns: 1fr 1fr;
    }
    .pool-slots{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .unfixed-slots{
        grid-template-columns: 1fr 1fr;
    }
    
    .slot{
        height: 70px;
        width: 80%;
        margin: 10%;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        position: relative;
        cursor: pointer;
    }
    
    .slot-name{
        font-size: 11px;
        font-weight: bold;
        position: absolute;
        left: 5px;
        top: 0px;
    }
    .slot-duration{
        font-size: 11px;
        font-weight: bold;
        position: absolute;
        left: 5px;
        bottom: 0px;
    }

    .resting-tag,
    .unavailable-tag,
    .available-tag{
        font-size: 10px;
        font-weight: bold;
        position: absolute;
        bottom: 0px;
        right: 5px;
    }

    .unavailable-tag{
        color: red;
    }

    .available-tag{
        color: green;
    }

    .resting-tag{
        color: darkblue;
    }

    .slot-filter{
        border-radius: 0.5vmax;
        background-color: rgba(0,0,0,0.4);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        display: none;
    }

    .Message0,
    .Message1,
    .Message2,
    .Message4{
        width: 120px;
        height: 120px;
        box-shadow: 0px 0px 50px 0px lightblue;
        background-color: white;
        padding: 5px;
        position: fixed;
        top: 25%;
        z-index: 1001;
    }

    .Message3{
        width: 120px;
        height: 200px;
        box-shadow: 0px 0px 50px 0px lightblue;
        background-color: white;
        padding: 5px;
        position: fixed;
        top: 25%;
        z-index: 1001;
    }

    .message0,
    .message1,
    .message2,
    .message3,
    .message4{
        text-align: center;
    }

    .close-message1{
        position: absolute;
        top: -50px;
        right: -15px;
        font-size: 17px;
        font-weight: bold;
        color: black;
        cursor: pointer;
    }

    @media (max-width: 1000px){
        .car-slots,
        .house-slots,
        .garden-slots,
        .pool-slots,
        .unfixed-slots{
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (max-width: 900px){
        .booking-body5,
        .booking-body4,
        .booking-body3,
        .booking-body2,
        .booking-body{
            width: 70dvw;
        }

        .submit-block{
            width: 70dvw;
        }
    }
    @media (max-width: 700px){
        .booking-body5,
        .booking-body4,
        .booking-body3,
        .booking-body2,
        .booking-body{
            width: 90dvw;
        }

        .submit-block{
            width: 90dvw;
        }
    }
    @media (max-width: 550px){
        .option p{
            font-size: 12px;
            font-weight: bold;
        }
    }
    @media (max-width: 500px){
        .booking-body5,
        .booking-body4,
        .booking-body3,
        .booking-body2,
        .booking-body{
            width: 100dvw;
            margin: 0px;
            border-radius: 0px;
        }

        .submit-block{
            width: 90dvw;
        }
        .booking-title{
            font-size: 30px;
            height: 60px;
            margin-top: 20px;
        }
        .car-slots,
        .house-slots,
        .garden-slots,
        .pool-slots,
        .unfixed-slots{
            grid-template-columns: 1fr;
        }

        .main-disclaimer,
        .main-disclaimer4,
        .main-disclaimer5{
            font-size: 15px;
        }
        .time,
        .submit-block h3{
            font-size: 12px;
        }
        .time{
            margin-top: 12px;
        }
 
        .option{
            display: grid;
            grid-template-columns: 70px 1fr 70px;
            width: 100%;
        }
        
        .option div{
            height: 13px;
            width: 13px;
            border-radius: 50%;
            border: 2px solid black;
            position: relative;
            top: 8px;
            left: 30px;
            cursor: pointer;
            box-shadow: 0px 0px 3px 0px black;
        }

    }
    @media (max-width: 450px){

        .disclaimer-4{
            left: 10%;
            top: 25px;
            width: 80%;
            font-size: 10px;
        }
        .disclaimer2,
        .disclaimer{
            font-size: 12px;
        }
        .maxTime{
            font-size: 10px;
            top: 22px;
            left: 0px;
            width: 100%;
            text-align: center;
        }
        .Spec h4{
            margin: 0px;
            margin-top: 5px;
            position: relative;
            left: 30px;
            width: 90%;
            font-size: 15px;
        }

        .card-label{
            width: 90%;
        }
        .card-label h1{
            margin: 0px;
            width: 100%;
            margin-top: 20px;
            height: 60px;
            font-size: 20px;
        }
        .main-disclaimer,
        .main-disclaimer4,
        .main-disclaimer5{
            font-size: 13px;
        }
        .room-count p{
            font-size: 12px;
        }
        .room-count h3,
        .car-count h3{
            font-size: 15px;
            margin-left: 5px;
            margin-right: 0px;
        }
        .rooms div,
        .car-count div{
            width: 80px;
            height: 40px;
        }
        .room-count button,
        .car-count button{
            height: 40px;
        }

        .rooms{
            grid-template-columns: 1fr 120px;
        }
        .close-booking{
            font-size: 15px;
            top: 15px;
        }
        .Appointment-date{
            margin-left: 10px !important;
        }

    }
    @media (max-width: 300px){
        .rooms{
            grid-template-rows: 33px 1fr;
            grid-template-columns: none;
            justify-items: center;
        }
        .room-count{
            height: 270px;
        }
        .room-count h3 {
            margin-left: 0px;
        }
    }
}
#booking-page::-webkit-scrollbar{
    width: 0px;
}

