#CancelBooking{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;

    div{
        width: 500px;
        height: 250px;
        border-radius: 1vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: lightblue;
        position: relative;
        margin-top: 30vh;
    }
    h1{
        width: 90%;
        margin: 5%;
        margin-top: 40px;
        text-align: center;
    }
    .Delete{
        width: 150px;
        height: 40px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 10px;
        left: 15%;
        color: white;
        background-color: black;
        cursor: pointer;
    }
    .Cancel{
        width: 150px;
        height: 40px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 10px;
        right: 15%;
        color: white;
        background-color: black;
        cursor: pointer;
    }
    @media(max-width: 600px){
        div{
            width: 100%;
            height: 100vh;
            border-radius: 0vmax;
            box-shadow: 0px 0px 0px 0px;
            background-color: lightblue;
            position: relative;
            margin-top: 0vh;
        }
        .Delete,
        .Cancel{
            width: 100px;
            height: 35px;
            bottom: 30px;
        }
        h1{
            font-size: 30px !important;
        }
    }
    @media(max-width: 500px){

        .Delete,
        .Cancel{
            width: 70px;
            height: 30px;
        }
        h1{
            font-size: 25px !important;
        }
    }
}

#CancelBooking::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255,255,255, 0.3);
}
#CancelBooking::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255,255,255, 0.7);
    border-radius: 4vmax;
}

@media (max-width: 500px){
    #CancelBooking::-webkit-scrollbar{
        width: 0px;
    }
    #CancelBooking::-webkit-scrollbar-thumb{
        width: 0px;
    }
}