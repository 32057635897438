#AccountFlag{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: rgba(0,0,0,0.7);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    z-index: 30;

    .message-card{
        width: 600px;
        height: 460px;
        border-radius: 1vmax;
        background-color: lightblue;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        position: relative;
    }
    .terms{
        color: black;
        font-weight: bold;
        text-decoration: underline;
    }
    .close-flag{
        position: absolute;
        top: 10px;
        right: 15px;
        font-weight: bold;
        font-size: 20px;
        width: fit-content;
        margin: 0px;
        cursor: pointer;
    }
    .message-card::-webkit-scrollbar{
        background-color: rgba(255,255,255, 0.9);;
        border-top-right-radius: 1vmax;
        border-bottom-right-radius: 1vmax;
        width: 2px;
    }
    .message-card::-webkit-scrollbar-thumb{
        width: 2px;
        background-color: rgba(14, 52, 65, 0.9);
        border-radius: 4vmax;
    }
    h1{
        text-align: center;
    }
    .flag-message{
        width: 80%;
    }
    @media(max-width: 650px){
        .message-card{
            width: 100vw;
            height: 100dvh;
            overflow-y: scroll;
            overflow-x: hidden;
            background-color: rgba(173, 216, 230, 0.8);
            border-radius:0px;
        }
    }
    @media(max-width: 500px){
        h1{
            font-size: 30px;
        }
        p{
            font-size: 14px;
        }
    }
    @media(max-width: 350px){
        h1{
            font-size: 25px;
        }
        p{
            font-size: 12px;
            font-weight: bold;
        }
    }
}