.Thumbnail2{
    border: 3px solid lightblue;
    border-radius: 1vmax;
    box-shadow: 0px 0px 4px 0px black;
    height: 60px;
    width: 200px;
    flex-shrink: 0;
    position: absolute;
    left: 10%;
    background-color: white;
    cursor: pointer;
}

.Thumbnail2 p{
    width: 100%;
    height: 100%;
    text-align: center;
}