#AboutUs-page{
    margin-top: 80px;

    .mission-block{
        height: 520px;
        width: 100dvw;
        position: relative;
        background-color: lightblue;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .mission-block img{
        position: absolute;
        height: 100%;
        width: 100dvw;
        opacity: 0.5;
    }
    .mission-block p,
    .mission-block h2{
        text-align: center;
        color: white;
    }
    
    .mission-block h2{
        font-size: 70px;
        width: 100dvw;
        margin-bottom: 10px;
        z-index: 10;
    }
    .mission-block p{
        width: 500px;
        font-size: 20px;
        z-index: 10;
    }
    
    .story-block{
        height: 520px;
        width: 100dvw;
        display: grid;
        grid-template-columns: 1fr 1.6fr;
        position: relative;
    }
    
    .story-image{
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .story-frame{
        height: 350px;
        width: 300px;
        border-radius: 0.6vmax;
        border: solid 6px lightblue;
        position: relative;
        top: 85px;
        overflow: hidden;
    }
    .story-image img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .story-text{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .story-text h2{
        text-align: center;
        font-size: 70px;
        width: 500px;
        margin-bottom: 10px;
    }
    .story-text p{
        width: 500px;
        text-align: center;
        font-size: 20px;
    }
    
    .team-block{
        height: 613px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
    }
    .team-block h2{
        font-size: 70px;
        width: 500px;
        text-align: center;
    }
    
    .item-roll{
        height: 390px;
        width: 100dvw;
        display: grid;
        grid-template-columns: 15% 70% 15%;
    }
    .item-roll button{
        height: 50px;
        width: 50px;
        border-radius: 100%;
        font-size: 20px;
        color: white;
        border: 0px;
        box-shadow: 0px 0px 2px 0px black;
        background-color: rgba(0,0,0,0.7);
        position: relative;
        left: 35%;
        top: 37%;
        z-index: 10;
    }
    
    .items-block{
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }
    .items{
        width: 750px;
        position: relative;
    }

    @media (max-width: 1000px){
        .story-block{
            height: 900px;
            grid-template-columns: none;
            grid-template-rows: 0.4fr 1fr;
        }

        .story-image{
            align-items: center;
        }
        .story-frame{
            position: relative;
            top: 0%;
            margin-top: 40px;
            height: 300px;
            width: 250px;
        }
    
    }

    @media (max-width: 600px){
        .item-roll{
            grid-template-columns: 0px 1fr 0px;
        }
        .item-roll button{
            position: relative;
        }
        .button1{
            left: 30px !important;
        }
        .button2{
            left: -70px !important;
        }
        .story-block{
            height: 800px;
            grid-template-columns: none;
            grid-template-rows: 0.4fr 1fr;
        }
        .story-frame{
            position: relative;
            top: 0%;
            margin-top: 40px;
            height: 250px;
            width: 200px;
        }
    }

    @media (max-width: 550px) {

        .mission-block h2{
            font-size: 40px;
            width: 80%;
        }
        .mission-block p{
            width: 80%;
            font-size: 15px;
            font-weight: bold;
        }
        .story-text h2{
            font-size: 40px;
            width: 80%;
        }
        .story-text p{
            width: 80%;
            font-size: 15px;
            font-weight: bold;
        }
        .team-block h2{
            font-size: 35px;
            width: 80%;
        }
    }
    @media (max-width: 350px) {
        .item-roll button{
            height: 40px;
            width: 40px;
        }
        .button1{
            left: 10px !important;
        }
        .button2{
            left: -50px !important;
        }
        .mission-block h2{
            font-size: 30px;
            width: 75%;
        }
        .mission-block p{
            width: 80%;
            font-size: 14px;
            font-weight: bold;
        }
        .story-text h2{
            font-size: 30px;
            width: 75%;
        }
        .story-text p{
            width: 75%;
            font-size: 14px;
            font-weight: bold;
        }
        .team-block h2{
            font-size: 30px;
            width: 75%;
        }
    }
}
